import { useState } from 'react';

const UseModalCoti = () => {
  const [isShowingCoti, setIsShowingCoti] = useState(false);

  function toggleCoti() {
    setIsShowingCoti(!isShowingCoti);
  }

  return {
      UseModalCoti,
    isShowingCoti,
    toggleCoti,
  }
};

export default UseModalCoti;