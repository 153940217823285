import React from "react";
import './Nosotros.scss'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import imgNosotros1 from '../../assets/nosotros/imgNosotros1.png';
import imgNosotros2 from '../../assets/nosotros/imgNosotros2.png';
import imgNosotros3 from '../../assets/nosotros/imgNosotros3.png';
import iconValores1 from '../../assets/nosotros/iconValores1.svg';
import iconValores2 from '../../assets/nosotros/iconValores2.svg';
import iconValores3 from '../../assets/nosotros/iconValores3.svg';
import iconValores4 from '../../assets/nosotros/iconValores4.svg';
import iconSepultura1 from '../../assets/nosotros/iconSepultura1.svg';
import iconSepultura2 from '../../assets/nosotros/iconSepultura2.svg';
import iconSepultura3 from '../../assets/nosotros/iconSepultura3.svg';
import { Divider } from '@material-ui/core';

const Nosotros = () => {

    return (
        <div className="NosotrosContainer">
            <section class="header-nosotros">
                <Navbar />
                <div class="container-title">
                    <h3>Sobre Nosotros</h3>
                </div>
            </section>
            <section class="body-nosotros">
                <div className="introUS">
                    <h3>Conózcanos</h3>
                    <p>Nos ilusiona contarle más sobre nosotros, porque creemos en lo que hacemos y en el esfuerzo que ponemos para lograrlo.</p>
                </div>
                <div id="carouselNosotros" class="carousel slide carousel-multi-item" data-ride="carousel" data-bs-interval="false">
                    <ol class="nosotros-indicators carousel-indicators">
                        <button type="button" data-bs-target="#carouselNosotros" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselNosotros" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselNosotros" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </ol>
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item active">
                            <div class="card" id="card1">
                                <div  className="backTitle">
                                    <img src={imgNosotros1} className="imgCard" alt="" width="320px" height="200px"/>
                                    <div className="backTitleContent">
                                        <h3>Quiénes <br/> somos</h3>
                                    </div>
                                </div>
                                <div class="card-body" id="card1">
                                    <p class="card-text">Llevamos más de 25 años trabajando para entregar el mejor servicio a nuestra comunidad. Queremos brindarle toda nuestra experiencia para poder afrontar esos momentos difíciles a usted y su familia.</p>
                                </div>
                            </div>    
                        </div>   
                        <div class="carousel-item">
                            <div class="card">
                                <div  className="backTitle">
                                    <img src={imgNosotros2} className="imgCard" alt="" width="320px" height="200px"/>
                                    <div className="backTitleContent">
                                        <h3>Misión</h3>
                                    </div>
                                </div>
                                <div class="card-body" id="card2">
                                    <p class="card-text">Ser la mejor empresa de Cementerios Parque, tanto para las familias como para quienes trabajamos en ella, entregando un servicio excelente y cercano con compromiso y respeto por la naturaleza y por usted.</p>
                                </div>
                            </div> 
                        </div>
                        <div class="carousel-item">
                            <div class="card">
                                <div  className="backTitle">
                                    <img src={imgNosotros3} className="imgCard" alt="" width="320px" height="200px"/>
                                    <div className="backTitleContent">
                                        <h3>Visión</h3>
                                    </div>
                                </div>
                                <div class="card-body" id="card3">
                                    <p class="card-text">Entregar apoyo y un servicio integral de excelencia a las familias que buscan un lugar de paz y tranquilidad donde honrar y homenajear a sus seres queridos.</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="valores">
                    <h3>Nuestros valores</h3>
                    <div className="items-valores">
                        <div className="content">
                            <div className="imgOurValues">
                                <img src={iconValores1} alt=""/>
                            </div>
                            <div className="text">
                                <h3>Respeto</h3>
                                <p>Mantenemos una actitud empática hacia las familias, dolientes y nuestros trabajadores, fomentando una relación amable y cortés, tratándonos como nos gustaría ser tratados.</p>
                            </div>
                        </div>
                        <div className="content">
                            <div className="imgOurValues">
                                <img src={iconValores2} alt=""/>
                            </div>
                            <div className="text">
                                <h3>Pasión</h3>
                                <p>Colocamos nuestro corazón para entregar lo mejor, comprometiéndonos con las familias. Sabemos que, en el difícil momento de la pérdida de un ser querido, nuestro trabajo produce un sentimiento que perdura.</p>
                            </div>
                        </div>
                        <div className="content">
                            <div className="imgOurValues">
                                <img src={iconValores3} alt=""/>
                            </div>
                            <div className="text">
                                <h3>Trabajo en equipo</h3>
                                <p>Confiamos en el aporte de las personas y creemos que nuestro éxito es resultado del<br/>
                                Trabajo en Equipo.<br/>
                                Nosotros nos llamamos por el nombre, demostrando interés en construir relaciones de largo plazo.</p>
                            </div>
                        </div>
                        <div className="content">
                            <div className="imgOurValues">
                                <img src={iconValores4} alt=""/>
                            </div>
                            <div className="text">
                                <h3>Excelencia</h3>
                                <p>Trabajamos para hacerlo bien a la primera, cuidando cada detalle, porque sabemos que NO existe una segunda oportunidad para hacerlo bien. Somos proactivos en buscar soluciones, entendiendo el dolor de las familias.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sepultura">
                    <h3>Nuestras Sepulturas</h3>
                    <p>Contamos con sepulturas de 2, 3 y 4 capacidades, distribuidas en las distintas zonas del parque. Su ubicación, si está en uso, está indicada con lápida.</p>
                    <p>Existen dos modalidades de compra: de <span>necesidad futura</span>, que permite a las familias prevenir un evento futuro, anticipando la compra de una sepultura, generando un ahorro del valor real; y de <span>necesidad inmediata</span>, que le permite a la familia hacer uso de una sepultura de manera inmediata ante el fallecimiento de un ser querido.</p>
                </div>
                <div class="card-group">
                    <div class="card">
                        <img src={iconSepultura1} class="d-block " alt=""/>
                        <div class="card-body ">
                            <h3>Capacidades</h3>
                            <p>En Memorial Park tenemos disponibles sepulturas de 2, 3 y 4 niveles.</p>
                        </div>
                    </div>
                    <Divider />
                    <div class="card">
                        <img src={iconSepultura2} class="d-block " alt=""/>
                        <div class="card-body ">
                            <h3>Sarcófago</h3>
                            <p>Bóveda de hormigón armado donde se deposita el ataúd, para resguardar de mejor forma los restos de nuestros seres queridos. Su uso es obligatorio.</p>
                        </div>
                    </div>
                </div>
                <div className="trabaja">
                    <div class="my-card card">
                        <div class="card-body ">
                            <h3>Trabaja con nosotros</h3>
                            <p>Si tiene interés en sumarse a este maravilloso equipo de trabajo,<br/> escríbanos y nos contactaremos</p>
                            <a href="info@memorialpark.com.bo">info@memorialpark.com.bo</a>
                        </div>
                    </div>
                </div>   
            </section>

            <Footer />
        </div>
    );
};

export default Nosotros;