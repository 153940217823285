import React, { useContext, useState }from "react";
import './Graveyard.scss'
import { DataContext } from '../../context';
import imgMap from '../../assets/home/Mapa.png'; 
import iconLocation from '../../assets/home/iconLocation.svg'; 
import iconSearch from '../../assets/home/IconSearch.svg'; 
// import imgDay4 from '../../assets/home/day4.png'; 
// import imgDay5 from '../../assets/home/day5.png'; 
// import imgDay6 from '../../assets/home/day6.png'; 
import { Divider } from '@material-ui/core';
import GoogleMaps from 'simple-react-google-maps';

const Graveyard = () => {
  const { setApellidoSepultados, getSepultados, hoy, mañana, pasadoMañana } = useContext( DataContext )
    
  const onChangeApellido = (e) =>{
      setApellidoSepultados(e.target.value);
  }

  const [toggleStateDay, setToggleStateDay] = useState(1);

  const toggleTabDay = (index, day) => {
    setToggleStateDay(index);
  };
 
  
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

    return (
        <section class="graveyard mt-5">
        <Divider id="horizontal" />
        <div className="containerMap">
          <div className="container-visit">
            <div className="visit">
              <h3>Visite Nuestro Parque</h3>
              <p>Lo invitamos a visitar Memorial Park</p>
              <div className="container">
                <div class="visit-info container d-flex flex-column">
                  <strong>Horarios de visita</strong>
                  <div>
                    <p style={{ margin:'0', display:'inline', float:'left'}}>De Lunes a Domingos</p>
                    <p style={{ margin:'0', display:'inline', float:'right'}}>8:00 a 17:00 hrs</p>
                  </div>
                  <strong>Ubicación del Parque</strong>
                  <div>
                    <p style={{ margin:'0'}}>Carretera a Cotoca KM 12, Santa Cruz, Bolivia</p>
                  </div>
                    <div className="mt-2">
                      <GoogleMaps
                          style={{ height: "400px", with: "100%" }}
                          zoom={16}
                          center={{lat: -17.7621690895085, lng: -63.0309437275737}}
                          //center={{ lat: lat, lng: lng }}
                          apiKey={"AIzaSyCwu6vtJb6jj5fg7jnGxq-m7r6I6sr_Se0"}
                          markers={{lat: -17.763058, lng: -63.030129}}
                      />
                        {/* <a href="https://maps.google.com/maps?ll=-17.762933,-63.029983&z=17&t=h&hl=es-419&gl=CL&mapclient=embed&cid=2850886267656641620">
                            <img src={imgMap}  alt="..." width="100%"/>
                        </a> */}
                    </div>
                    <div class="input-group d-flex justify-content-center mb-3 mt-4">
                        <div class="input-group-text" id="btnGroupAddon"><img src={iconLocation} alt="" /></div>
                        {/* <input type="button" class="form-control" placeholder="VER COMO LLEGAR" aria-label="Input group example" aria-describedby="btnGroupAddon"/> */}
                        <a href="https://www.google.com/maps/dir//-17.7629335,-63.0299836/@-17.762933,-63.029984,841m/data=!3m1!1e3?hl=es-419" target="_blank">
                        <button type="button" class="btn btn-primary btn-sm">CÓMO LLEGAR</button>
                        </a>
                    </div>
                    <strong>Horarios para trámites y pagos oficina central</strong>
                    <div>
                      <p style={{ margin:'0', display:'inline', float:'left'}}>De Lunes a Viernes</p>
                      <p style={{ margin:'0', display:'inline', float:'right'}}>8:30 a 16:30 hrs</p>
                    </div>
                    <div>
                      <p style={{ margin:'0', display:'inline', float:'left'}}>Sábados</p>
                      <p style={{ margin:'0', display:'inline', float:'right'}}>8:00 a 12:30 hrs</p>
                    </div>
                    <strong>Ubicación oficina central</strong>
                    <div style={{ marginBottom: '4.9rem'}}>
                      <p style={{ margin:'0'}}>C/Prolongación Campero esq. Acre, Nro. 194, Santa Cruz, Bolivia</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-location-obituary">
            <div className="location">
              <h3>Ubicación de sepultados</h3>
              <p>Ingrese algún dato de su ser querido para obtener su ubicación en el parque</p>
              <div class="input-group input-group-sm ">
                <span class="input-group-text" id="inputGroup-sizing-sm"><img src={iconSearch} class="d-flex" alt="..." width=""/></span>
                <input type="text" class="form-control" placeholder="Ingrese apellido paterno o materno de su ser querido..."  onChange={onChangeApellido} />
                {/* <TextField  label="C.I. o NIT" size="small"/> */}
              </div>
              <a href="/sepultados"><button type="button" class="btn btn-primary btn-sm" >CONSULTAR</button></a>
            </div>
            <div className="borderMapVertical"></div>
            <div className="obituary">
              <div className="borderMapHorizontal"></div>
              <h3>Obituario</h3>
              <p>Seleccione el día que quiere consultar.</p>
              <div class="img-days d-flex">
                <table>
                  <thead>
                      <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <div style={{ width: '6rem', textAlign:'center'}} className={toggleStateDay === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(1, hoy)} >
                          <p style={{lineHeight:'2.5rem'}} className={toggleStateDay === 1 ? "txtCalendarBoldH" : "txtCalendarH"}>Hoy</p>
                          <div name="iconDay" className={toggleStateDay === 1 ? "imgColorH" : "imgBlackH"}>
                            <h3>{hoy[0]}</h3>
                            <p className={toggleStateDay === 1 ? "imgColorTxtH" : "imgBlackTxtH"}>{hoy[1].slice(0,3)}</p>
                          </div>
                        </div>
                      </th>
                      {/* <th>
                        <div style={{ width: '6rem'}} className={toggleStateDay === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(2, mañana)} >
                          <p style={{lineHeight:'2.5rem', marginLeft:'11px'}} className={toggleStateDay === 2 ? "txtCalendarBoldH" : "txtCalendarH"}>Mañana</p>
                          <div name="iconDay" className={toggleStateDay === 2 ? "imgColorH" : "imgBlackH"}>
                            <h3>{mañana[0]}</h3>
                            <p className={toggleStateDay === 2 ? "imgColorTxtH" : "imgBlackTxtH"}>{mañana[1].slice(0,3)}</p>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div style={{ width: '6rem'}} className={toggleStateDay === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(3, pasadoMañana)} >
                          <p style={{lineHeight:'1.2rem'}} className={toggleStateDay === 3 ? "txtCalendarBoldH" : "txtCalendarH"} >Pasado Mañana</p>
                          <div name="iconDay" className={toggleStateDay === 3 ? "imgColorH" : "imgBlackH"}>
                            <h3>{pasadoMañana[0]}</h3>
                            <p className={toggleStateDay === 3 ? "imgColorTxtH" : "imgBlackTxtH"}>{pasadoMañana[1].slice(0,3)}</p>
                          </div>
                        </div>
                      </th> */}
                    </tr>
                  </tbody>
                </table>
              </div>
              <a href="/obituario" style={{ 'marginBottom': '2rem'}}><button type="button" class="btn btn-primary btn-sm">VER OBITUARIO</button></a>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Graveyard;

const hrColored = {
    color: '#3094D0',
    height: '2px',
    width: '58%',
    margin: '4.9rem'
};
