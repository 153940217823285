export const QuestionsGenerales = [
    {id:1, question: '¿Cuáles son los horarios de atención de Memorial Park?', response: 'Puede encontrar los horarios de atención de Memorial Park y de nuestra oficina comercial en el siguiente <a href="./informacion" target="_blank">link</a>'},
    {id:2, question: '¿Cómo puedo conocer la ubicación de un fallecido del Cementerio Parque?', response: 'Puede buscar la ubicación de un fallecido ingresando el Nombre, Apellido, CI o NIT, en el siguiente <a href="./sepultados/" target="_blank">link</a>'}
]
export const QuestionsSepultaciones = [
    {id:1, question:'¿Cuáles son los pasos a seguir ante el fallecimiento de un ser querido?', response: 'Para coordinar la sepultación de un fallecido deberá contar con algunos documentos e información del servicio funerario. El detalle de esta información la puede encontrar en el siguiente <a href="./fallecimiento" target="_blank">link</a> Una vez que usted cuente con toda la documentación, debe acercarse a Memorial Park para realizar el trámite de forma presencial.'}
]

export const QuestionsReducciones = [
    {id:1, question: '¿Qué es una reducción?', response: 'La reducción es la acción de reducir el cuerpo de un fallecido, de manera que sus restos pasen a ocupar sólo una parte del espacio correspondiente al féretro en que fueron inhumados.'}
]
export const QuestionsVentas= [
    {id:1, question:'¿Qué ventaja tiene adquirir una sepultura anticipada?', response: 'Adquirir una sepultura de manera anticipada puede entregar tranquilidad y beneficios a toda la familia. Además de esta tranquilidad, la compra anticipada le da acceso a menores valores de compra para uso inmediato y le permite realizar el pago en cuotas, acorde a su necesidad. Para conocer los distintos servicios que ofrece Memorial Park puede ingresar en el siguiente <a href="./parque" target="_blank">link</a>'},
    {id:2, question:'¿Cuáles son los valores para las sepulturas?', response: 'Contamos con diferentes precios que van desde los $us. 2.950 hasta los $us. 6.000 dependiendo la ubicación. *Precio de NF'},
    {id:3, question:'¿Dónde puedo pagar las cuotas de crédito y cuotas de mantención?', response: 'Puede pagarlas en nuestras oficinas centrales a través de transferencia Bancaria o en nuestros puntos de pagos externos. <a href="./informacion#scroll-medios-pago" target="_blank">Medios de pagos</a>'},
    {id:4, question:'¿Puedo pagar de manera presencial?', response: 'Puede hacerlo, en nuestra oficina central, Calle Prolongación Campero #194 Esq Acre \(1er Anillo Zona Maquina Vieja\) Santa Cruz – Bolivia.'}
]
export const QuestionsInstalaciones= [
    {id:1, question: '¿Cuentan con Capilla y Velatorio?', response: 'No por el momento.'},
    {id:2, question:'¿Puedo solicitar una ceremonia para conmemorar a un fallecido?', response: 'Puede solicitarlo en Atención al Cliente en nuestra oficina Central el cual tiene un costo de $us. 25'}
] 