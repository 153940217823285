import React, { useContext, useState } from "react";
import './Sepultados.scss'
import '../../components/Maps/Modal.css'
import iconSearch from '../../assets/home/IconSearch.svg';
import iconMenu from '../../assets/sepultados/iconMenuList.svg'
import arrowUp from '../../assets/sepultados/arrowUp.svg'
import arrowDown from '../../assets/sepultados/arrowDown.svg'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { DataContext } from '../../context';
import MaterialTable from 'material-table';
import Modal from "../../components/Maps/Modal";
import useModal from "../../components/Maps/useModal";
import jsPDF from 'jspdf';

const Sepultados = () => {
    const { rowsSepultados, setRowsSepultados, columnsSepultados, setApellidoSepultados, getSepultados, loading } = useContext(DataContext)
    const {isShowing, toggle} = useModal();
    const [label, setLabel] = useState("Nombre");
    const [showArrow, setShowArrow] = useState(false);
    const [order, setOrder] = useState(true);
    const [userData, SetUserData]= useState(null);
    const [coord, setCoord] = useState(null);

    const generatePDF = ()=> {
        let doc = new jsPDF ("p", "pt", "a4"); 
        doc.html(document.querySelector("#maps"),{
            callback: function(pdf){
                pdf.save("maps.pdf");
            }
        });}

    const sendLatLng = (rowData) => {
        SetUserData(rowData); 
        let coords= rowData?.latlon?.split(",");
        let lat= parseFloat(coords? coords[0] : null);
        let lng= parseFloat(coords? coords[1] : null);
        let coordenada = { lat: lat, lng: lng};
        setCoord(coordenada);
        toggle();
    }

    const onChangeApellido = (e) => {
        setApellidoSepultados(e.target.value);
    }

    const handlechangeOption = (desc, field, name) => {

        setLabel(name)
        setShowArrow(true)

        if (desc === false) {
            setOrder(false)
            let x = rowsSepultados.fallecidos.sort((p1, p2) => {
                if (p1[field] > p2[field]) {
                    return 1
                }
                if (p1[field] < p2[field]) {
                    return -1;
                }
                else return 0;

            })
            setRowsSepultados({ fallecidos: x });
        }
        else {
            setOrder(true)
            let x = rowsSepultados.fallecidos.sort((p1, p2) => {
                if (p1[field] > p2[field]) {
                    return -1
                }
                if (p1[field] < p2[field]) {
                    return 1;
                }
                else return 0

            })
            setRowsSepultados({ fallecidos: x });
        }

    }


    return (
        <div className="SepultadosContainer">
            <section class="header-sepultados">
                <Navbar />
                <div class="container-title">
                    <h3>Ubicación de Sepultados</h3>
                </div>
            </section>
            <section class="body-sepultados">
                <div className="button-tabs">
                    <a href="/obituario"><button id="button-obituario"> OBITUARIO </button></a>
                    <button id="button-ubicacion" > UBICACIÓN </button>
                </div>
                <p>Ingresa alguno de los siguientes datos para encontrar la ubicación de su ser querido</p>
                <div className="content-tabs">
                    <div class="input-group  input-group-sm" style={{ width:'22rem'}}>
                        <span class="input-group-text" id="inputGroup-sizing-sm"><img src={iconSearch} class="d-flex" alt="..." width="" /></span>
                        <input type="text" class="form-control" placeholder="Apellido paterno o materno  de su ser querido" onChange={onChangeApellido} />
                    </div>
                    <button type="button" class="btn btn-primary btn-sm" onClick={getSepultados}>CONSULTAR</button>
                </div>
                <div class="table" style={{ marginBottom:'4rem'}}>
                    {loading === true ?
                        <div>
                            <h3>Sepultados</h3>
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <div>
                            <h3>Sepultados</h3>
                            {rowsSepultados?.fallecidos ?
                                <div class="fallecidos">
                                    <p>{rowsSepultados.fallecidos.length} <span class="resuTotal">resultados</span></p>

                                    <div class="list-group">
                                        <div className="list-header">
                                            <div className="list-title">
                                                <p>{label}</p>
                                                <img src={order == true ? arrowDown : arrowUp} class={showArrow === true ? 'arrow' : 'disable'} alt="" />
                                            </div>
                                            <div class="dropdown">
                                                <button class="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={iconMenu} alt="" />
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><strong>Ordenar por</strong></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'nombres', 'Nombre')}>Nombre Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'nombres', 'Nombre')}>Nombre Descendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'apaterno', 'Apellido Paterno')}>Apellido Paterno Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'apaterno', 'Apellido Paterno')}>Apellido Paterno Descendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'amaterno', 'Apellido Materno')}>Apellido Materno Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'amaterno', 'Apellido Materno')}>Apellido Materno Descendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'sec', 'Sector')}>Sector Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'sec', 'Sector')}>Sector Descendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'sep', 'Sepultura')}>Sepultura Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'sep', 'Sepultura')}>Sepultura Descendente</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {rowsSepultados.fallecidos.map((row, i) => {
                                            return <div class="list-group-item">
                                                <div className="left">
                                                    <p id="name">{row.nombres}</p>
                                                    <p>{row.apaterno}&nbsp;{row.amaterno}</p>
                                                    <p id="sec">Sector: {row.sec}</p>
                                                    <p>Sepultura: {row.sep}</p>
                                                    <p>Fecha de sepultura:{row.fechasep}</p>
                                                </div>
                                                <div className="rigth">
                                                    <button type="button" class="btn btn-primary btn-sm btnUbicacionList" 
                                                        style={{ paddingLeft:'0.6rem'}}
                                                        onClick={() => sendLatLng(row)}
                                                        >UBICACIÓN</button>
                                                </div>
                                            </div>

                                        })}
                                    </div>

                                    <MaterialTable
                                        columns={columnsSepultados}
                                        data={rowsSepultados.fallecidos}
                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: 'No se encontraror registros'
                                            },
                                            toolbar: {
                                                searchTooltip: 'Buscar'
                                            },
                                            pagination: {
                                                labelRowsSelect: 'Registros',
                                                labelDisplayedRows: '{from}-{to} de {count}',
                                                firstTooltip: 'Primera página',
                                                previousTooltip: 'Página anterior',
                                                nextTooltip: 'Próxima página',
                                                lastTooltip: 'Última página',
                                                labelRowsPerPage: 'Registros por página'
                                            }
                                        }}
                                        options={{
                                            sorting: true,
                                            search: false,
                                            toolbar: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10, 20, 30],
                                            paginationType: "stepped",
                                            actionsColumnIndex: -1,
                                            headerStyle: {
                                                backgroundColor: '#575757',
                                                color: '#FFF'
                                            },
                                            rowStyle: rowData => ({
                                                backgroundColor: rowData.tableData.id % 2 === 0? '#F8F8F8' : '#FFFFFF',
                                                textTransform: 'capitalize'
                                            }),
                                        }}
                                        actions={[
                                            {
                                                onClick: (event, rowData) => {
                                                    SetUserData(rowData); 
                                                    let coords= rowData?.latlon?.split(",");
                                                    let lat= parseFloat(coords? coords[0] : null);
                                                    let lng= parseFloat(coords? coords[1] : null);
                                                    let coordenada = { lat: lat, lng: lng};
                                                    setCoord(coordenada);
                                                    toggle(); }
                                            }
                                        ]}
                                        components={{
                                            Action: props => (
                                                <button type="button" class="btn btn-info btnUbicacionList"  onClick={(event) => props.action.onClick(event, props.data)}>
                                                    UBICACIÓN
                                                </button>
                                            ),
                                        }}
                                    />
                                </div>
                                : <p>Sin resultados</p>}
                        </div>
                    }

                </div>

            </section>
            <Footer />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                userData={userData}
                coord={coord}
                generatePDF={generatePDF}/>

        </div>  
    );
};

export default Sepultados;