import React, { useState }  from "react";
import './Park.scss'
import Navbar from '../../components/Navbar/Navbar';
import Graveyard from "../../components/Graveyard/Graveyard";
import Footer from '../../components/Footer/Footer';
import Cotizacion from "../../components/Cotizacion/Cotizacion";
import UseModalCoti from "../../components/Cotizacion/UseModalCoti";
import pdf from "../../assets/park/MEMORIAL_PARK_Reglamento_Interno.pdf";

import iconScroll from '../../assets/park/iconScroll.svg';
import Logo from '../../assets/navbar/logo.svg'
// import imgInfo1 from '../../assets/park/imgInfoM1.png'
import imgInfo1 from '../../assets/park/sepulturasPC.png'
import imgInfo1M from '../../assets/park/sepulturasPC.png'
import tmb_imgInfo1M from '../../assets/park/tmb_imgInfoM1.svg'
import imgInfo2 from '../../assets/park/exuberanteNaturalezaPC.png'
// import imgInfo2M from '../../assets/park/imgInfoM2.png'
// import tmb_imgInfo2M from '../../assets/park/tmb_imgInfoM2.png'
import imgInfo2M from '../../assets/park/tmb_imgInfoM2PC.png'
import tmb_imgInfo2M from '../../assets/park/tmb_imgInfoM2.png'

// import imgInfo3 from '../../assets/park/imgInfo3.svg'
import imgInfo3 from '../../assets/park/imgPark_Card3AsesoramientoBig_PC.png'
import imgInfo3M from '../../assets/park/imgPark_Card3AsesoramientoBig_PC.png'
import tmb_imgInfo3M from '../../assets/park/tmb_imgInfoM3.png'
import imgGallery1 from '../../assets/park/imgGallery1.jpg';
import imgGalleryM1 from '../../assets/park/imgGalleryM1.svg';
import imgGallery2 from '../../assets/park/imgGallery2.jpg';
import imgGalleryM2 from '../../assets/park/imgGalleryM2.svg';
import imgGallery3 from '../../assets/park/imgGallery3.jpg';
import imgGalleryM3 from '../../assets/park/imgGalleryM3.svg';
import imgGallery4 from '../../assets/park/imgGallery4.jpg';
import imgGalleryM4 from '../../assets/park/imgGalleryM4.svg';
import imgGallery5 from '../../assets/park/imgGallery5.jpg';
import imgGalleryM5 from '../../assets/park/imgGalleryM5.svg';
import imgGallery6 from '../../assets/park/imgGallery6.jpg';
import imgGalleryM6 from '../../assets/park/imgGalleryM6.svg';
import iconService1 from '../../assets/park/iconService1.svg';
import iconService2 from '../../assets/park/iconService2.svg';
import iconService3 from '../../assets/park/iconService3.svg';
import iconService4 from '../../assets/park/iconService4.svg';
import iconService5 from '../../assets/park/iconService5.svg';
import rectangle from '../../assets/park/Rectangle270.svg'
import { Divider } from '@material-ui/core';
import Gallery from 'react-grid-gallery';

import areas01 from '../../assets/park/areas01.jpg';
import areas02 from '../../assets/park/areas02.jpg';
import areas03 from '../../assets/park/areas03.jpg';
import areas04 from '../../assets/park/areas04.jpg';
import areas05 from '../../assets/park/areas05.jpg';
import areas06 from '../../assets/park/areas06.jpg';
import areas01M from '../../assets/park/areas01M.jpg';
import areas02M from '../../assets/park/areas02M.jpg';
import areas03M from '../../assets/park/areas03M.jpg';
import areas04M from '../../assets/park/areas04M.jpg';
import areas05M from '../../assets/park/areas05M.jpg';
import areas06M from '../../assets/park/areas06M.jpg';
import jardines01 from '../../assets/park/jardines01.jpg';
import jardines02 from '../../assets/park/jardines02.jpg';
import jardines03 from '../../assets/park/jardines03.jpg';
import jardines04 from '../../assets/park/jardines04.jpg';
import jardines05 from '../../assets/park/jardines05.jpg';
import jardines06 from '../../assets/park/jardines06.jpg';
import jardines01M from '../../assets/park/jardines01M.jpg';
import jardines02M from '../../assets/park/jardines02M.jpg';
import jardines03M from '../../assets/park/jardines03M.jpg';
import jardines04M from '../../assets/park/jardines04M.jpg';
import jardines05M from '../../assets/park/jardines05M.jpg';
import jardines06M from '../../assets/park/jardines06M.jpg';
import nosotros01 from '../../assets/park/nosotros01.jpg';
import nosotros02 from '../../assets/park/nosotros02.jpg';
import nosotros03 from '../../assets/park/nosotros03.jpg';
import nosotros04 from '../../assets/park/nosotros04.jpg';
import nosotros05 from '../../assets/park/nosotros05.jpg';
import nosotros06 from '../../assets/park/nosotros06.jpg';
import nosotros01M from '../../assets/park/nosotros01M.jpg';
import nosotros02M from '../../assets/park/nosotros02M.jpg';
import nosotros03M from '../../assets/park/nosotros03M.jpg';
import nosotros04M from '../../assets/park/nosotros04M.jpg';
import nosotros05M from '../../assets/park/nosotros05M.jpg';
import nosotros06M from '../../assets/park/nosotros06M.jpg';


const IMAGES =
[{
  src: areas01,
  thumbnail: areas01M,
  thumbnailWidth: 180,
  thumbnailHeight: 180
        
},
{
    src: areas02,
    thumbnail: areas02M,
    thumbnailWidth: 180,
    thumbnailHeight: 180
          
},
{
    src: areas03,
    thumbnail: areas03M,
    thumbnailWidth: 180,
    thumbnailHeight: 180
          
},
{
    src: areas04,
    thumbnail: areas04M,
    thumbnailWidth: 180,
    thumbnailHeight: 180
          
},
{
    src: areas05,
    thumbnail: areas05M,
    thumbnailWidth: 180,
    thumbnailHeight: 180
          
},
{
    src: areas06,
    thumbnail: areas06M,
    thumbnailWidth: 180,
    thumbnailHeight: 180
          
}];
      
const IMAGES1 =
[{
  src: jardines01,
  thumbnail: jardines01M,
  thumbnailWidth: 270,
  thumbnailHeight: 180
        
},
{
    src: jardines02,
    thumbnail: jardines02M,
    thumbnailWidth: 240,
    thumbnailHeight: 180
          
},
{
    src: jardines03,
    thumbnail: jardines03M,
    thumbnailWidth: 120,
    thumbnailHeight: 180
          
},
{
    src: jardines04,
    thumbnail: jardines04M,
    thumbnailWidth: 135,
    thumbnailHeight: 180
          
},
{
    src: jardines05,
    thumbnail: jardines05M,
    thumbnailWidth: 240,
    thumbnailHeight: 180
          
},
{
    src: jardines06,
    thumbnail: jardines06M,
    thumbnailWidth: 240,
    thumbnailHeight: 180
          
}];

const IMAGES2 =
[{
  src: nosotros01,
  thumbnail: nosotros01M,
  thumbnailWidth: 270,
  thumbnailHeight: 180
        
},
{
    src: nosotros02,
    thumbnail: nosotros02M,
    thumbnailWidth: 270,
    thumbnailHeight: 180
          
},
{
    src: nosotros03,
    thumbnail: nosotros03M,
    thumbnailWidth: 270,
    thumbnailHeight: 180
          
},
{
    src: nosotros04,
    thumbnail: nosotros04M,
    thumbnailWidth: 120,
    thumbnailHeight: 180
          
},
{
    src: nosotros05,
    thumbnail: nosotros05M,
    thumbnailWidth: 240,
    thumbnailHeight: 180
          
},
{
    src: nosotros06,
    thumbnail: nosotros06M,
    thumbnailWidth: 120,
    thumbnailHeight: 180
          
}];
      
//const IMAGES = IMAGES1.concat(IMAGES2);
      
const Park = () => {
    
    const [toggleStateInfo, setToggleStateInfo] = useState(1);
    const [toggleStateGallery, setToggleStateGallery] = useState(1);
    const {isShowingCoti, toggleCoti}= UseModalCoti()
    
    const toggleTabInfo = (index) => {
      setToggleStateInfo(index);
    };

    const toggleTabGallery = (index) => {
      setToggleStateGallery(index);
    };

    const handleModal = ()=>{
       toggleCoti();
    }
    
    return (
      <div className="PageContainer">
    <section class="header-park">
      <Navbar transparent/>
      <div class="container-title">
        <h3>Un lugar único <br/>Conoce Nuestro Parque</h3>
      </div>
      <div class="container-scroll">
        <p>Conozca más</p>
        <a class="nav-link" href="#scroll-cotizar"><img src={iconScroll} alt=""/></a>
        {/* <button class="btn" type="button"><img src={iconScroll} alt=""/></button> */}
      </div>
    </section>
    <section id="scroll-cotizar" class="cotizar">
      <img src={Logo} alt=""/>
      <p><b>Un espacio único</b> donde honrar y homenajear a nuestros seres queridos. Un punto de encuentro para reunirse en familia, en espacios <b>rodeados de plena naturaleza</b>, que entregan toda la <b>paz y tranquilidad</b> que solo <b>Memorial Park</b> puede brindar gracias a la responsabilidad con que trabajamos.</p>
      <button type="button" class="btn btn-primary btn-sm"  onClick={handleModal} >COTIZAR</button>
      <Cotizacion
      isShowingCoti={isShowingCoti}
      hideCoti={toggleCoti}
      />
    </section>
    <section id="servicesHome" class="info">
        {/* Caraousel Informacion para mobile */}
        <div id="carouselInformation" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
          <div class="information-indicators carousel-indicators">
            <button type="button" data-bs-target="#carouselInformation" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselInformation" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselInformation" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={imgInfo2M} class="d-block " alt="..." width="336px" height="443px" className="cardImg"/>
              <div class="carousel-caption d-flex justify-content-center ">
                <div class="title-card">
                  <h3>Exuberante <br/>naturaleza</h3>
                </div>
                <div class="text-card">
                  <p>Amplios ambientes naturales, bosques y jardines, cuidados por un gran equipo humano. Porque amamos la naturaleza y la vida, y queremos brindar espacios de paz y tranquilidad.</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img src={imgInfo1M} class="d-block " alt="..." width="336px" height="443px" className="cardImg"/>
              <div class="carousel-caption d-flex justify-content-center ">
                <div class="title-card">
                  <h3>Sepulturas de tipo unifamiliar</h3>
                </div>
                <div class="text-card">
                  <p>Ofrecemos espacios únicos, para sepulturas unifamiliar de dos, tres y cuatro capacidades.</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img src={imgInfo3M} class="d-block " alt="..." width="336px" height="443px" className="cardImg"/>
              <div class="carousel-caption d-flex justify-content-center ">
                <div class="title-card">
                  <h3>Asesoramiento gratuito</h3>
                </div>
                <div class="text-card">
                  <p>Contáctenos y ponemos a su disposición un equipo de personasespecializadas para brindarle la informaciónnecesaria.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Informacion para PC */}
        <div class="info-tabs">
          <div className="content-tabs">
            <div className={toggleStateInfo === 1 ? "content  active-content" : "content myCursor"}>
              <div className="card">
                <h3>Exuberante naturaleza</h3>
                <p>Amplios ambientes naturales, bosques y jardines, cuidados por un gran equipo humano. Porque amamos la naturaleza y la vida, y queremos brindar espacios de paz y tranquilidad.</p>
              </div>
              <img src={imgInfo2} alt="" className="cardImg"/>
            </div>
            <div className={toggleStateInfo === 2 ? "content  active-content" : "content myCursor"}>
              <div className="card" style={{ marginLeft:'9px'}}>
                <h3>Sepulturas de tipo unifamiliar</h3>
                <p>Ofrecemos espacios únicos, para sepulturas unifamiliar de dos, tres y cuatro capacidades.</p>
              </div>
              <img src={imgInfo1} alt="" className="cardImg"/>
            </div>
            <div className={toggleStateInfo === 3 ? "content  active-content" : "content myCursor"}>
              <div className="card" style={{ marginLeft:'1px'}}>
                <h3>Asesoramiento gratuito</h3>
                <p>Contáctenos y pondremos a su disposición un equipo de personas especializadas para brindarle la información necesaria.</p>
              </div>
              <img src={imgInfo3} alt="" className="cardImg"/>
            </div>
          </div>
          <div className="bloc-tabs">
               <div className={toggleStateInfo === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabInfo(1)} >
                  <p>Exuberante naturaleza</p>
                  <img src={tmb_imgInfo2M} alt="" width="135px" height="135px"/>
               </div>
               <div className={toggleStateInfo === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabInfo(2)} >
                  <p>Sepulturas de tipo unifamiliar</p>
                  <img src={tmb_imgInfo1M} alt="" width="135px" height="135px"/>
               </div>
               <div className={toggleStateInfo === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabInfo(3)} >
                  <p>Asesoramiento gratuito</p>
                  <img src={tmb_imgInfo3M} alt="" width="135px" height="135px"/>
               </div>
          </div>
        </div>
        <Divider id="horizontalSlide" />
        <div class="container-dudas">
          <h3>¿Quiere conocer más sobre lo que podemos ofrecerle?</h3>
          <p>Nuestros ejecutivos estarán felices de contarle todo sobre Memorial Park</p>
          <button type="button" class="btn btn-primary btn-sm" onClick={handleModal} >CONSULTAR</button>
        </div>
        </section>
        <section class="image-gallery">
          <h3 className="titleImgGallery">CONOZCA NUESTRO PARQUE</h3>
          <div className="container-gallery">
             <div className="bloc-tabs">
               <button className={toggleStateGallery === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabGallery(1)} > Aéreas </button>
               <button className={toggleStateGallery === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabGallery(2)} > Naturaleza </button>
               <button className={toggleStateGallery === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabGallery(3)} > Nosotros </button>
             </div>
             <div className="content-tabs">
               <div className={toggleStateGallery === 1 ? "content  active-content" : "content"}>
                {/* <div className="container-images"> */}
                <Gallery images={IMAGES} enableImageSelection={false} enableKeyboardInput={true}/>
               </div>
               <div className={toggleStateGallery === 2 ? "content  active-content" : "content"}>
                <Gallery images={IMAGES1} enableImageSelection={false} enableKeyboardInput={true}/>
               </div>
               <div className={toggleStateGallery === 3 ? "content  active-content" : "content"}>
                <Gallery images={IMAGES2} enableImageSelection={false} enableKeyboardInput={true}/>
               </div>
             </div>
          </div>

          <div class="container-visitar">
              <h3>Estamos prestos a cualquier consulta</h3>
              <p>Ponemos nuestro corazón en crear espacios únicos para las familias.</p>
              <button type="button" class="btn btn-primary btn-sm" onClick={handleModal} >CONTACTAR</button>
            </div>
        </section>
        <section class="services">
          <img id="rectangle" src={rectangle} alt="" width="62px"/>
          <h3>Otros servicios</h3>
          <div className="service">
            <div className="content">
              <img src={iconService1} alt=""/>
              <p>Ofrecemos un seguro de vida de hasta 1.000 US$ en efectivo de uso inmediato, vitalicio para el titular o primer evento de familia durante 25 años.</p>
            </div>
            <div className="content">
              <img src={iconService2} alt=""/>
              <p>Personal capacitado.<br/> Seguridad 24 hrs.<br/> Atención inmediata y personalizada.</p>
            </div>
            <div className="content">
              <img src={iconService3} alt=""/>
              <p>Liberación de la deuda (seguro de desgravamen).<br/> Solidez económica y financiera.<br/> Planes de financiamiento.</p>
            </div>
            <div className="content">
              <img src={iconService4} alt=""/>
              <p>Inhumación bajo césped con bóveda de hormigón, lápida de granito grabada y derecho de uso de perpetuidad.</p>
            </div>
            <div className="content">
              <img src={iconService5} alt=""/>
              <p>Mantenimiento y cuidado del Parque, conservando el respeto por la naturaleza.</p>
            </div>
          </div>
        </section>
        <Graveyard/>
        <section class="documentation">
          <div className="questions">
            <h3>Documentación del parque</h3>
            <p>Le dejamos links a algunos documentos relevantes del parque.</p>
            {/* <button type="button" class="btn btn-primary btn-sm mt-2">REGLAMENTO INTERNO</button> */}
            <a type="button" class="btn btn-primary btn-sm mt-2" download href={pdf}>REGLAMENTO INTERNO</a>
          </div>
      </section>
      <Footer />
    </div>
  );
};

export default Park;