import React, { useState } from 'react';
import './Cotizacion.scss'
import emailjs from 'emailjs-com';
import ReactDOM from 'react-dom';
import { Formik } from 'formik';

const Cotizacion = ({ isShowingCoti, hideCoti }) => {
  const [showMessage, setShowMessage] = useState(false);

  return (

    isShowingCoti ? ReactDOM.createPortal(
      <React.Fragment>
        <div className="modal-overlay2" />
        <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1} role="dialog"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close btn-close-white" onClick={hideCoti} ></button>
                <p>Deje sus datos y aproveche</p>
                <h3>Nuestros descuentos online</h3>
              </div>
              <div class="modal-body">

                {showMessage ? <div className="alert alert-success col-md-5 mx-auto" role="alert">La solicitud de cotización se ha enviado correctamente</div> : ``}
                <Formik
                  initialValues={{ interes: '', nombre: '', Apellido: '', ApellidoMaterno: '', telefono: '', email: '' }}
                  validate={values => {
                    let errors = {};
                    if (!values.email) {
                      errors.email = 'El email es requerido';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'Debe ingresar un formato de email valido';
                    }

                    if (!values.nombre){
                      errors.nombre = 'Debe ingresar un nombre';
                    } else if (
                      !/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.nombre)
                    ) {
                      errors.nombre = 'El nombre solo puede contener letras y espacios'
                    }
                    if (!values.Apellido){
                      errors.Apellido = 'Debe ingresar un apellido';
                    } else if (
                      !/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.Apellido)
                    ) {
                      errors.Apellido = 'El apellido solo puede contener letras y espacios'
                    }
                    if (!values.ApellidoMaterno){
                      errors.ApellidoMaterno = 'Debe ingresar un apellido materno';
                    } else if (
                      !/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.ApellidoMaterno)
                    ) {
                      errors.ApellidoMaterno = 'El apellido materno solo puede contener letras y espacios'
                    }

                    if (!values.telefono){
                      errors.telefono = 'Debe ingresar un telefono';
                    } 
                    else if (
                      !/^[0-9]{1,8}$/g.test(values.telefono)
                    ) {
                      errors.telefono = 'El numero de telefono debe tener hasta 8 digitos'
                    }

                    if (!values.interes){
                      errors.interes = 'Debe seleccionar una opcion';
                    }


                    return errors;
                  }}
                  onSubmit={(values) => {
                    console.log(values.email)
                    emailjs.send('default_service', 'template_29btuij', values, 'user_4lFOCE5PsoRvvRs43ggs8')
                      .then((response) => {
                        console.log('SUCCESS!', response.status, response.text);
                        hideCoti()
                      }, (err) => {
                        console.log('FAILED...', err);
                        hideCoti()
                      });
                   
                  }}
                >
                  {({
                    handleBlur,
                    isSubmitting,
                    errors,  
                    handleSubmit, 
                    values,
                    handleChange, 
                    touched
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div class="row">
                        <div class="col">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput"
                              placeholder="Nombre"
                              onChange={handleChange}
                              name="nombre"
                              value={values.nombre}
                              onBlur={handleBlur}
                            />
                             {touched.nombre && errors.nombre && <div id="error">{errors.nombre} </div>}
                            <label for="floatingInput"></label>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput"
                              placeholder="Apellido paterno"
                              onChange={handleChange}
                              name="Apellido"
                              value={values.Apellido}
                              onBlur={handleBlur}
                            />
                            {touched.Apellido && errors.Apellido && <div id="error">{errors.Apellido}</div>}
                            <label for="floatingInput"></label>
                          </div>
                        </div>
                      </div>
                      <div class="row">&nbsp;</div>
                      <div class="row">
                        <div class="col">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput"
                              placeholder="Apellido materno"
                              onChange={handleChange}
                              name="ApellidoMaterno"
                              value={values.ApellidoMaterno}
                              onBlur={handleBlur}
                            />
                             {touched.ApellidoMaterno && errors.ApellidoMaterno && <div id="error">{errors.ApellidoMaterno}</div>}
                            <label for="floatingInput"></label>
                          </div>
                        </div>
                        <div class="col ">
                          <div class="form-floating">
                            <input
                              type="number"
                              class="form-control"
                              id="floatingInput"
                              placeholder="Teléfono"
                              onChange={handleChange}
                              name="telefono"
                              value={values.telefono}
                              onBlur={handleBlur}
                            />
                            {touched.telefono && errors.telefono && <div id="error">{errors.telefono}</div>}
                            <label for="floatingInput"></label>
                          </div>
                        </div>
                      </div>
                      <div class="row">&nbsp;</div>
                      <div class="row">
                        <div class="col">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Su dirección de e-mail"
                              onChange={handleChange}
                              name="email"
                              value={values.email}
                              onBlur={handleBlur}
                            />
                            {touched.email && errors.email && <div id="error">{errors.email} </div>}
                            <label for="floatingInput"></label>
                          </div>
                        </div>
                      </div>
                      <div class="row">&nbsp;</div>
                      <div class="row">
                        <div class="col">
                          <select className="form-control custom-select custom-select-lg mb-3 comboInt"
                            style={{ padding: '1rem' }}
                            value={values.interes}
                            onChange={handleChange}
                            name="interes"
                            onBlur={handleBlur}
                          >
                            <option value='' >Selecccione la cantidad de capacidades que desea</option>
                            <option value="Sepultura 2 niveles" >Sepultura 2 niveles</option>
                            <option value="Sepultura 3 niveles">Sepultura 3 niveles</option>
                            <option value="Sepultura 4 niveles">Sepultura 4 niveles</option>
                            <option value="No lo tengo decidido">No lo tengo decidido</option>
                          </select>
                            {touched.interes && errors.interes && <div id="error">{errors.interes} </div>}
                        </div>
                      </div>
                      <div className="pt-3 col-md-5 mx-auto text-left m-4 divBtnCotizar">
                        <button className="btn btn-info btnCotizar" type="submit" disabled={isSubmitting} >COTIZAR</button>
                      </div>
                    </form>
                  )
                  }
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>, document.getElementById('modal')
    ) : null
  )
}
export default Cotizacion;
