import React from "react";
import "./Footer.scss";
import iconFace from "../../assets/navbar/iconFace.svg";
import iconIG from "../../assets/navbar/ig.svg";
import iconYoutube from "../../assets/navbar/iconYoutube.svg";
import iconWsp from "../../assets/navbar/iconWsp.svg";

const Footer = () => {

    return(
        <footer>
            <div className="footer">
                <div className="contact">
                    <h3>Contacto</h3>
                    <div class="d-flex flex-column align-items-start">
                        <p class="d-flex flex-start p-1">Tel: &nbsp;&nbsp;(+591) 77399188 - (+591) 3361404</p>
                        <p class="d-flex flex-start p-1">Mail: &nbsp;<a href="mailto:info@memorialpark.com.bo">info@memorialpark.com.bo</a></p>
                        {/* <strong class="d-flex flex-start">Direccion:</strong>
                        <small class="d-flex flex-start text-muted">Carretera a Cotoca KM 12, Santa Cruz, Bolivia</small>
                        <strong class="d-flex flex-start">Horarios:</strong>
                    <small class="d-flex flex-start text-muted text-truncate">Lunes a Domingos &nbsp;&nbsp; 9:00h - 18:00h</small> */}
                    </div>
                    <div className="d-flex flex-start align-items-start">
                        <a href="https://m.facebook.com/CementerioMemorialParkSantaCruz/" target="_blank"><img src={iconFace} alt="" width="33px" height="33px"/></a>
                        <a href="https://instagram.com/memorialparkbo" target="_blank"><img src={iconIG} alt="" width="33px" height="33px"/></a>
                        <a href="https://api.whatsapp.com/send?phone=+59177399188&text=Informaciones" target="_blank"><img src={iconWsp} alt="" width="33px" height="33px"/></a>
                    </div>
                </div>
                <div className="site-map">
                    <h3>Mapa del sitio</h3>
                    <ul class="nav nav-pills flex-column align-items-start list-group" id="menu">
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="50%">
                                    <li class="nav-item" style={{width: '9rem'}}>
                                        <a href="/parque" class="nav-link text-truncate">
                                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Nuestro Parque</span>
                                        </a>
                                    </li>
                                </td>
                                <td width="50%">
                                    <li class="nav-item">
                                        <a href="/preguntas" class="nav-link text-truncate">
                                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Preguntas Frecuentes</span>
                                        </a>
                                    </li>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%">
                                    <li class="nav-item" style={{width: '9rem'}}>
                                        <a href="/nosotros" class="nav-link text-truncate">
                                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Nosotros</span>
                                        </a>
                                    </li>
                                </td>
                                <td width="50%">
                                    <li class="nav-item">
                                        <a href="/sepultados" class="nav-link text-truncate">
                                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Ubicación Sepultados</span>
                                        </a>
                                    </li>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%">
                                    <li class="nav-item" style={{width: '9rem'}}>
                                    <a href="/informacion" class="nav-link text-truncate">
                                        <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Información General</span>
                                    </a>
                                    </li>
                                </td>
                                <td width="50%">
                                    <li class="nav-item">
                                        <a href="/nosotros" class="nav-link text-truncate">
                                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Nosotros</span>
                                        </a>
                                    </li>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%">
                                    <li class="nav-item" style={{width: '9rem'}}>
                                        <a href="/obituario" class="nav-link text-truncate">
                                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Obituario</span>
                                        </a>
                                    </li>
                                </td>
                                <td width="50%">
                                    <li class="nav-item">
                                        <a href="/noticias" class="nav-link text-truncate">
                                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">En caso de Fallecimiento</span>
                                        </a>
                                    </li>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        {/* <li class="nav-item">
                            <a href="/parque" class="nav-link text-truncate">
                                <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Nuestro Parque</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/nosotros" class="nav-link text-truncate">
                                <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Nosotros</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/informacion" class="nav-link text-truncate">
                                <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Informacion</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/preguntas" class="nav-link text-truncate">
                                <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Preguntas Frecuentes</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/obituario" class="nav-link text-truncate">
                                <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Obituario</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/sepultados" class="nav-link text-truncate">
                                <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Ubicación Sepultados</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="/noticias" class="nav-link text-truncate">
                                <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Noticias</span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="copy">
                <p>© Copyright 2020 | Todos los derechos reservados Memorial Park</p>
            </div>
            <div className="conditionPrivacy">
                <p><a href={process.env.PUBLIC_URL + '/MEMORIAL_PARK_Condiciones_Generales_de_Uso_del_Sitio.pdf'} target="_blank">Condiciones generales</a> | <a href={process.env.PUBLIC_URL + '/MEMORIAL_PARK_Politica_de_Privacidad.pdf'}>Privacidad</a></p>
            </div>
        </footer>
    );
};
    
export default Footer;