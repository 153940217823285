import React from 'react'
import '../../pages/Preguntas/Preguntas.scss';

const QuestionAcord = ({ question }) => {
  return (
    //     <div className="container">
    //     <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Toggle both elements</button>
    //   <div class="row">
    //     <div class="col">
    //       <div class="collapse multi-collapse" id="multiCollapseExample1">
    //         <div class="card card-body">
    //           Some placeholder content for the first collapse component of this multi-collapse example. This panel is hidden by default but revealed when the user activates the relevant trigger.
    //         </div>
    //       </div>
    //     </div>
    //     <div class="col">
    //       <div class="collapse multi-collapse" id="multiCollapseExample2">
    //         <div class="card card-body">
    //           Some placeholder content for the second collapse component of this multi-collapse example. This panel is hidden by default but revealed when the user activates the relevant trigger.
    //         </div>
    //       </div>
    // </div>
    //   </div> 
    //     </div>

    // ---------------------------------------------------------------------------------

    <div>
      <div class="accordion m-4" id={`#accordionExample${question.id}`} key={question.id}>
        <div class="accordion-item text txtFAQ">
          <h2 class="accordion-header" id={`#headingOne${question.id}`}>
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                data-bs-target={`#collapse${question.id}`} aria-expanded="false" data-disabled="true"
                aria-controls={`collapse${question.id}`}>
              {question.question}
            </button>
          </h2>
          <div id={`collapse${question.id}`} class="accordion-collapse collapse" 
            aria-labelledby={`#headingOne${question.id}`} 
            data-bs-parent="#accordionPanelsStayOpenExample">
            <div class="accordion-body">
              <div dangerouslySetInnerHTML={{__html: question.response}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionAcord
