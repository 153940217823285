import React from "react";
import Notice from './Notice';
import "./Notices.scss";
import { useContext, useState } from "react";
import { DataContext } from "../../context";
import { useHistory } from "react-router-dom";
import iconArrrow from "../../assets/preguntas/arrow.png";
import iconArrrowInv from "../../assets/preguntas/arrowInv.png";
import { types } from '../../Actions';

//Images for individual notices
import imgNews1 from '../../assets/home/Noticias1.svg';
import imgNews2 from '../../assets/home/Noticias4.svg';
import imgNews3 from '../../assets/home/Noticias3.svg';
import imgNews4 from '../../assets/home/Noticias2.svg';

function Notices() {
    const { store, dispatch } = useContext(DataContext)
    const { news, newsGeneral } = store;
    let history = useHistory();
    const [Ascendente, setAscendente] = useState(false)
    const [Noticias, setNoticias] = useState(false)
    const [noMasNoticas, setnoMasNoticas] = useState(false)

    const handleClick = () => {
        //history.push = "/"
        setAscendente(!Ascendente)
    }

    const sortAsc = () => {
        let asc = news?.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
        return asc?.map(news => (
            <Notice news={news} />
        ))
    }

    const sortDesc = () => {
        let desc = news?.sort((a, b) =>
            new Date(b.fecha) - new Date(a.fecha))
        return desc?.map(news => (
            <Notice news={news} />
        ))

    }

    

    const masNoticias = () => {
        setNoticias(!Noticias)
        setTimeout(()=> {
            setNoticias(false)
        }, 2000)
        setnoMasNoticas(!noMasNoticas)
    }


    return (
        <div className="widthNotices">
            {newsGeneral ?
                <div>
                    <button
                        type="button"
                        class="btn btn-link text-secondary text-decoration-none buttone btnSort"
                        onClick={handleClick}
                        >
                        Fecha &nbsp;
                        <img src={Ascendente ? iconArrrowInv : iconArrrow} alt=""/>
                    </button>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">

                                {Ascendente ?
                                    sortAsc()
                                    :
                                    sortDesc()
                                }
                            </div>
                            <div className="col-md-4 colum marginMore">
                                <h5 class="mt-3 mb-3 titulo">Más noticias</h5>
                                <p class="text-secondary">
                                    <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                            type: types.newsChange,
                                            payload: [{ id:1, image: imgNews1, fecha: '2019-09-21', titulo: "Planta tu amor por Santa Cruz y Fauna chiquitana", cuerpo: "Memorial Park agradece a DESAFÍO FAUNA CHIQUITANA por haber ayudado a que nuestra actividad “Planta tu amor por Santa Cruz” que se realiza todos los años, en esta oportunidad haya tenido un fin benéfico para la Chiquitania que tanto nos necesita. También agradecemos a las miles de personas que realizaron sus donativos a cambio de plantines, los cuales serán gestionados por Desafío Fauna Chiquitana." }]
                                        })}>
                                        Planta tu amor
                                    </a>
                                </p>
                                <p class="text-secondary">
                                    <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                            type: types.newsChange,
                                            payload: [{ id:2, image: imgNews2, fecha: '2020-02-04', titulo: "Reforestación de nuestros bosques", cuerpo: "Reafirmando nuestro compromiso con la naturaleza, el día sábado participamos de la tercera precarnavalera junto a la comparsa coronadora 2020 “Chiripas jr.”, con quienes repartimos 400 plantines. Agradecemos a los coronadores por la invitación y por incentivar a la reforestación de nuestros bosques." }]
                                        })}>
                                        Reforestación de nuestros bosques
                                    </a>
                                </p>
                                <p class="text-secondary">
                                    <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                            type: types.newsChange,
                                            payload: [{ id:3, image: imgNews3, fecha:'2020-10-05', titulo: "Plantar un árbol es plantar vida", cuerpo: "Reafirmando nuestro compromiso con la Naturaleza, el día sábado 03 de octubre participamos de la campaña de Medio Ambiente “Plantar un árbol es plantar vida”, organizado por la Asociación Internacional de Clubes de Leones. Hicimos la entrega de 60 plantines, los cuales fueron plantados en Satélite Norte, y embellecerán aún más a Santa Cruz. Memorial Park comprometidos con la naturaleza y su familia." }]
                                        })}>
                                        Plantar un árbol es plantar vida
                                    </a>
                                </p>
                                <p class="text-secondary">
                                    <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                            type: types.newsChange,
                                            payload: [{ id:4, image: imgNews4, fecha:'2020-10-31', titulo: "Salvando vidas", cuerpo: "Porque nuestro compromiso es con usted, su familia y la naturaleza…Por eso nos sentimos felices y orgullosos de hacer la entrega al Hospital Municipal de la Ciudad de Cotoca, a través de nuestra campaña SALVANDO VIDAS, de 3 ambulancias cero (0) Km debidamente equipadas para la lucha contra el COVID-19." }]
                                        })}>
                                        Salvando vidas
                                    </a>
                                </p>
                                <button
                                    type="button"
                                    class="btn btn-link text-secondary text-decoration-none buttone"
                                    onClick={masNoticias}
                                >VER MÁS</button>
                                { Noticias ?
                                <div>
                                    <h3>Buscando...</h3>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Cargando...</span>
                                    </div>
                                </div>
                                : (
                                    noMasNoticas ?
                                    <p class="text-secondary">NO HAY MAS NOTICIAS</p>
                                    : 
                                    ""
                                )

                                } 
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-8">

                            {Ascendente ?
                                sortAsc()
                                :
                                sortDesc()
                            }
                        </div>
                        <div className="col-md-4 colum">
                            <h5 class="mt-3 mb-3 titulo">Más noticias</h5>
                            <p class="text-secondary">
                                <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                        type: types.newsChange,
                                        payload: [{ id:1, image: imgNews1, fecha: '2019-09-21', titulo: "Planta tu amor por Santa Cruz y Fauna chiquitana", cuerpo: "Memorial Park agradece a DESAFÍO FAUNA CHIQUITANA por haber ayudado a que nuestra actividad “Planta tu amor por Santa Cruz” que se realiza todos los años, en esta oportunidad haya tenido un fin benéfico para la Chiquitania que tanto nos necesita. También agradecemos a las miles de personas que realizaron sus donativos a cambio de plantines, los cuales serán gestionados por Desafío Fauna Chiquitana." }]
                                    })}>
                                    Planta tu amor
                                </a>
                            </p>
                            <p class="text-secondary">
                                <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                        type: types.newsChange,
                                        payload: [{ id:2, image: imgNews2, fecha: '2020-02-04', titulo: "Reforestación de nuestros bosques", cuerpo: "Reafirmando nuestro compromiso con la naturaleza, el día sábado participamos de la tercera precarnavalera junto a la comparsa coronadora 2020 “Chiripas jr.”, con quienes repartimos 400 plantines. Agradecemos a los coronadores por la invitación y por incentivar a la reforestación de nuestros bosques." }]
                                    })}>
                                    Reforestación de nuestros bosques
                                </a>
                            </p>
                            <p class="text-secondary">
                                <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                        type: types.newsChange,
                                        payload: [{ id:3, image: imgNews3, fecha:'2020-10-05', titulo: "Plantar un árbol es plantar vida", cuerpo: "Reafirmando nuestro compromiso con la Naturaleza, el día sábado 03 de octubre participamos de la campaña de Medio Ambiente “Plantar un árbol es plantar vida”, organizado por la Asociación Internacional de Clubes de Leones. Hicimos la entrega de 60 plantines, los cuales fueron plantados en Satélite Norte, y embellecerán aún más a Santa Cruz. Memorial Park comprometidos con la naturaleza y su familia." }]
                                    })}>
                                    Plantar un árbol es plantar vida
                                </a>
                            </p>
                            <p class="text-secondary">
                                <a style={{ cursor:'pointer'}} onClick={() => dispatch({
                                        type: types.newsChange,
                                        payload: [{ id:4, image: imgNews4, fecha:'2020-10-31', titulo: "Salvando vidas", cuerpo: "Porque nuestro compromiso es con usted, su familia y la naturaleza…Por eso nos sentimos felices y orgullosos de hacer la entrega al Hospital Municipal de la Ciudad de Cotoca, a través de nuestra campaña SALVANDO VIDAS, de 3 ambulancias cero (0) Km debidamente equipadas para la lucha contra el COVID-19." }]
                                    })}>
                                    Salvando vidas
                                </a>
                            </p>
                            <button
                                type="button"
                                class="btn btn-link text-secondary text-decoration-none buttone"
                                onClick={masNoticias}
                            >VER MÁS</button>
                            { Noticias ?
                                <div>
                                    <h3>Buscando...</h3>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Cargando...</span>
                                    </div>
                                </div>
                                : (
                                    noMasNoticas ?
                                    <p class="text-secondary">NO HAY MAS NOTICIAS</p>
                                    : 
                                    ""
                                )
                                } 
                        </div>
                    </div>

                </div>

            }
        </div>
    )
}

export default Notices
