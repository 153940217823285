import React, { useState } from "react";
import "./Navbar.scss";
import logo1 from "../../assets/navbar/logo.svg";
import logo2 from "../../assets/home/iconMemorialP.svg"
import iconMenuB from "../../assets/navbar/iconMenuB.svg"
import iconMenuW from "../../assets/navbar/iconMenuW.svg"
import iconFace from "../../assets/navbar/iconFace.svg";
import iconIG from "../../assets/navbar/ig.svg";
import iconFaceColor from "../../assets/navbar/iconFaceColor.svg";
import iconYoutube from "../../assets/navbar/iconYoutube.svg";
import iconYoutubeColor from "../../assets/navbar/iconYoutubeColor.svg";
import iconWsp from "../../assets/navbar/iconWsp.svg";
import iconWspColor from "../../assets/navbar/iconWspColor.svg";
import { Divider } from '@material-ui/core';

const NavBar = ({transparent}) => {
    //navbar transparente o blanco
    const navT = "my-nav navbar navbar-expand-lg navbar-light navTransparent";
    const navW = "my-nav navbar navbar-expand-lg navbar-light navWhite";

    return (
        <div className="shadowImg">
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" data-bs-keyboard="false" data-bs-backdrop="false" aria-labelledby="offcanvasWithBackdropLabel">
            <div class="offcanvas-header">
                <h6 class="offcanvas-title d-sm-block" id="offcanvas">Menu</h6>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <ul class="nav nav-pills flex-column align-items-start list-group" id="menu">
                    <li class="nav-item">
                        <a href="/parque" class="nav-link text-truncate">
                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Nuestro Parque</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/nosotros" class="nav-link text-truncate">
                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Nosotros</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/informacion" class="nav-link text-truncate">
                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Información General</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/obituario" class="nav-link text-truncate">
                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Obituario</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/sepultados" class="nav-link text-truncate">
                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Ubicación Sepultados</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/noticias" class="nav-link text-truncate">
                            <i class="fs-5 bi-house"></i><span class="d-sm-inline text-dark">Noticias</span>
                        </a>
                    </li>
                </ul>
                <Divider />
                <a href="/fallecimiento" class="my-link nav-link text-truncate">
                    <i class="rounded-start"></i><span class="d-sm-inline">En Caso de Fallecimiento</span>
                </a>
                {/* nav nav-pills flex-column mb-sm-auto mb-0 align-items-start list-group */}
                <div class="contact">
                    <h3 class="d-flex flex-start ">Contacto</h3>
                    <div class="d-flex flex-column">
                            <strong class="d-flex flex-start">Telefono:</strong>
                            <small class="d-flex flex-start text-muted"> (+591) 773991881 – (+591) 3361404</small>
                            <strong class="d-flex flex-start">Mail:</strong>
                            <small class="d-flex flex-start text-muted"><a href="mailto:info@memorialpark.com.bo">info@memorialpark.com.bo</a></small>
                            <strong class="d-flex flex-start">Direccion:</strong>
                            <small class="d-flex flex-start text-muted">Carretera a Cotoca KM 12, Santa Cruz, Bolivia</small>
                            <strong class="d-flex flex-start">Horarios:</strong>
                            <small class="d-flex flex-start text-muted text-truncate">Lunes a Domingos &nbsp;&nbsp; 9:00h - 18:00h</small>
                    </div>
                    <div className="d-flex flex-start">
                        <a href="https://m.facebook.com/CementerioMemorialParkSantaCruz/" target="_blank"><img src={iconFaceColor} alt="" width="33px" height="33px"/></a>
                        <a href="https://instagram.com/memorialparkbo" target="_blank"><img src={iconIG} alt="" width="33px" height="33px"/></a>
                        <a href="https://api.whatsapp.com/send?phone=+59177399188&text=Informaciones" target="_blank"><img src={iconWspColor} alt="" width="33px" height="33px"/></a>
                    </div>
                </div>
            </div>
          </div>
          
          <div id="outer">
            <div class="inner" style={{ width:'80%', maxWidth:'71rem', marginTop:'0.2rem' }}>
              <div class="inner" style={{ float:'left', marginLeft:'1.5rem' }}>
                <p href="#" class="white-text cabecera"> (+591) 77399188 – (+591) 3361404</p>&nbsp;&nbps;
              </div>
              <div class="inner" style={{ float:'left', marginLeft:'1rem' }}>
                <a href="mailto:info@memorialpark.com.bo" style={{ textDecoration:'none', color:'white' }}>info@memorialpark.com.bo</a>
              </div>
              <div class="inner" style={{ float:'right'}}>
                <div className="icons">
                    <a href="https://m.facebook.com/CementerioMemorialParkSantaCruz/" target="_blank"><img src={iconFace} alt="" width="22px" height="23px"/></a>&nbsp;&nbsp;&nbsp;
                    <a href="https://instagram.com/memorialparkbo" target="_blank"><img src={iconIG} alt="" width="22px" height="23px"/></a>&nbsp;&nbsp;&nbsp;

                    <a href="https://api.whatsapp.com/send?phone=+59177399188&text=Informaciones" target="_blank"><img src={iconWsp} alt="" width="29px" height="20px"/></a>
                </div>
              </div>
            </div>
          </div>
          
            {/* <div className="my-container ">
                <p href="#" class="white-text cabecera"> (+591) 77399188 – (+591) 3361404 &nbsp; <a href="mailto:info@memorialpark.com.bo">info@memorialpark.com.bo</a></p>
                <div className="icons">
                    <a href="https://www.facebook.com/memorialparksantacruz" target="_blank"><img src={iconFace} alt="" width="22px" height="23px"/></a>
                    <a href="https://api.whatsapp.com/send?phone=+59177399188&text=Informaciones" target="_blank"><img src={iconWsp} alt="" width="29px" height="20px"/></a>
                </div>
            </div> */}
            <nav id="navbar_top" class={transparent ? navT : navW}>
                <div class="container">
                    <a class="navbar-brand" href="/"><img src={transparent ? logo2 : logo1} alt="" class="logo" id="navbar-logo"/></a>
                    <button class="navbar-toggler" type="button"  role="button">
                        {/* <i  class="navbar-toggler-icon" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i> */}
                        <img src={transparent ? iconMenuW : iconMenuB} alt="" class="icon" id="navbar-menu" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"/>
                    </button>
                    {/* <div class="collapse navbar-collapse" id="navbarSupportedContent"> */}
                    
                    <ul class="navbar-nav " style={{textAlign: 'center'}}>
                        <li class="nav-item">
                            <a class="nav-link" href="/parque">Nuestro Parque</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/nosotros">Nosotros</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/informacion">Información General</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/obituario">Obituario</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" style={{lineHeight: '16px'}} href="/sepultados">Ubicación Sepultados</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/noticias">Noticias</a>
                        </li>
                        <li class="nav-item custom">
                            <a class="nav-link " href="/fallecimiento">En Caso de Fallecimiento</a>
                        </li>
                    </ul>
                    
                    {/* </div> */}
                </div>
            </nav>
        </div>
      
        
        );
};
    
export default NavBar;