import React, { useContext, useState, useEffect } from "react";
import './Obituario.scss'
import '../../components/Maps/Modal.css'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { DataContext } from '../../context';
import MaterialTable from 'material-table';
import Modal from "../../components/Maps/Modal";
import useModal from "../../components/Maps/useModal";
import jsPDF from 'jspdf';
import arrowUp from '../../assets/sepultados/arrowUp.svg';
import arrowDown from '../../assets/sepultados/arrowDown.svg';
import iconMenu from '../../assets/sepultados/iconMenuList.svg'

const Obituario = () => {
    const { hoy, mañana, pasadoMañana, getObituario, columnsObituario, rowsObituario, setRowsObituario, loading } = useContext(DataContext)

    const [toggleStateDay, setToggleStateDay] = useState(1);
    const [label, setLabel] = useState("Nombre");
    const [showArrow, setShowArrow] = useState(false);
    const [order, setOrder] = useState(true);
    const [userData, SetUserData]= useState(null);
    const [coord, setCoord] = useState(null);
    const {isShowing, toggle} = useModal();
    
    const generatePDF = ()=> {
        let doc = new jsPDF ("p", "pt", "a4"); 
        doc.html(document.querySelector("#maps"),{
            callback: function(pdf){
                pdf.save("maps.pdf");
            }
        });}

    const toggleTabDay = (index, day) => {
        setToggleStateDay(index);
        getObituario(day);
    };

    const sendLatLng = (rowData) => {
        SetUserData(rowData); 
        let coords= rowData?.latlon?.split(",");
        let lat= parseFloat(coords? coords[0] : null);
        let lng= parseFloat(coords? coords[1] : null);
        let coordenada = { lat: lat, lng: lng};
        setCoord(coordenada);
        toggle();
    }

    const handlechangeOption = (desc, field, name) => {

        setLabel(name)
        setShowArrow(true)

        if (desc === false) {
            setOrder(false)
            let x = rowsObituario.sort((p1, p2) => {
                if (p1[field] > p2[field]) {
                    return 1
                }
                if (p1[field] < p2[field]) {
                    return -1;
                }
                else return 0;

            })
            setRowsObituario(x);
        }
        else {
            setOrder(true)
            let x = rowsObituario.sort((p1, p2) => {
                if (p1[field] > p2[field]) {
                    return -1
                }
                if (p1[field] < p2[field]) {
                    return 1;
                }
                else return 0

            })
            setRowsObituario(x);
        }

    }

    useEffect(() => {
        getObituario(hoy);
    }, [])

    return (
        
        
        <div className="ObituarioContainer">
            <section class="header-obituario">
                <Navbar />
                <div class="container-title">
                    <h3>Obituario</h3>
                </div>
            </section>
            <section class="body-obituario">
            <div className="optMovil">
                    <div className="button-tabs">
                        <a href="/sepultados"><button id="button-ubicacion" > UBICACIÓN </button></a>
                        <button id="button-obituario"> OBITUARIO </button>
                    </div>
                    <p>Seleccione la fecha que desea consultar.</p>
                    <div className="content-tabs alignElements">
                        <table>
                            <tr>
                                <td>
                                    <div style={{ width: '6rem'}} className={toggleStateDay === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(1, hoy)} >
                                        <p style={{lineHeight:'2.5rem', marginLeft:'0.3rem'}} className={toggleStateDay === 1 ? "txtCalendarBold" : "txtCalendar"}>Hoy</p>
                                        <div Name="iconDay" className={toggleStateDay === 1 ? "imgColor" : "imgBlack"}>
                                            <h3>{hoy[0]}</h3>
                                            <p className={toggleStateDay === 1 ? "imgColorTxt" : "imgBlackTxt"}>{hoy[1].slice(0,3)}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ width: '6rem'}} className={toggleStateDay === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(2, mañana)} >
                                        <p style={{lineHeight:'2.5rem'}} className={toggleStateDay === 2 ? "txtCalendarBold" : "txtCalendar"}>Mañana</p>
                                        <div Name="iconDay" className={toggleStateDay === 2 ? "imgColor" : "imgBlack"}>
                                            <h3>{mañana[0]}</h3>
                                            <p className={toggleStateDay === 2 ? "imgColorTxt" : "imgBlackTxt"}>{mañana[1].slice(0,3)}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ width: '6rem'}} className={toggleStateDay === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(3, pasadoMañana)} >
                                        <p style={{lineHeight:'1.2rem'}} className={toggleStateDay === 3 ? "txtCalendarBold" : "txtCalendar"} >Pasado Mañana</p>
                                        <div Name="iconDay" className={toggleStateDay === 3 ? "imgColor" : "imgBlack"}>
                                            <h3>{pasadoMañana[0]}</h3>
                                            <p className={toggleStateDay === 3 ? "imgColorTxt" : "imgBlackTxt"}>{pasadoMañana[1].slice(0,3)}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="optPc">
                    <table style={{ width:'100%'}}>
                        <tr>
                            <td style={{ width:'45%'}}>
                                <div className="button-tabs alignElementsL">
                                    <a href="/sepultados"><button id="button-ubicacion" > UBICACIÓN </button></a>
                                    <button id="button-obituario"> OBITUARIO </button>
                                </div>
                                <p style={{ marginLeft: '-1.7rem'}}>Selecciona la fecha a consultar.</p>
                            </td>
                            <td style={{ width:'55%'}}>
                                <div className="content-tabs alignElementsR">
                                    <table>
                                        <tr>
                                            <td>
                                                <div style={{ width: '6rem'}} className={toggleStateDay === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(1, hoy)} >
                                                    <p style={{lineHeight:'2.5rem', marginLeft:'0.3rem'}} className={toggleStateDay === 1 ? "txtCalendarBold" : "txtCalendar"}>Hoy</p>
                                                    <div Name="iconDay" className={toggleStateDay === 1 ? "imgColor" : "imgBlack"}>
                                                        <h3>{hoy[0]}</h3>
                                                        <p className={toggleStateDay === 1 ? "imgColorTxt" : "imgBlackTxt"}>{hoy[1].slice(0,3)}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ width: '6rem'}} className={toggleStateDay === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(2, mañana)} >
                                                    <p style={{lineHeight:'2.5rem'}} className={toggleStateDay === 2 ? "txtCalendarBold" : "txtCalendar"}>Mañana</p>
                                                    <div Name="iconDay" className={toggleStateDay === 2 ? "imgColor" : "imgBlack"}>
                                                        <h3>{mañana[0]}</h3>
                                                        <p className={toggleStateDay === 2 ? "imgColorTxt" : "imgBlackTxt"}>{mañana[1].slice(0,3)}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ width: '6rem'}} className={toggleStateDay === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTabDay(3, pasadoMañana)} >
                                                    <p style={{lineHeight:'1.2rem'}} className={toggleStateDay === 3 ? "txtCalendarBold" : "txtCalendar"} >Pasado Mañana</p>
                                                    <div Name="iconDay" className={toggleStateDay === 3 ? "imgColor" : "imgBlack"}>
                                                        <h3>{pasadoMañana[0]}</h3>
                                                        <p className={toggleStateDay === 3 ? "imgColorTxt" : "imgBlackTxt"}>{pasadoMañana[1].slice(0,3)}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </table>  
                </div>
                <div class="table" style={{ marginBottom:'4rem'}}>
                    {loading === true ?
                        <div style={{textAlign: 'center'}}>
                            <h3>Buscando...</h3>
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Cargando...</span>
                            </div>
                        </div>
                        :
                        <div>
                            <h3>Obituario</h3>
                            {rowsObituario ?
                                <div class="fallecidos">
                                    <p>{rowsObituario.length} <span class="resuTotal">resultados</span></p>

                                    <div class="list-group">
                                        <div className="list-header">
                                            <div className="list-title">
                                                <p>{label}</p>
                                            </div>
                                            <div class="dropdown">
                                                <button class="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={iconMenu} alt="" />
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><strong>Ordenar por</strong></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'nombres', 'Nombre')}>Nombre Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'nombres', 'Nombre')}>Nombre Descendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'sec', 'Sector')}>Sector Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'sec', 'Sector')}>Sector Descendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(false, 'sep', 'Sepultura')}>Sepultura Ascendente</a></li>
                                                    <li><a class="dropdown-item" onClick={() => handlechangeOption(true, 'sep', 'Sepultura')}>Sepultura Descendente</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {rowsObituario.map((row, i) => {
                                            return <div class="list-group-item">
                                                <div className="left">
                                                    <p id="name">{row.nombre}</p>
                                                    <p style={{ marginTop:'1.3rem'}}>Hora: {row.hora}</p>
                                                    <p>Area: {row.area}</p>
                                                    <p>Sector: {row.sec}</p>
                                                    <p>Sepultura: {row.sep}</p>
                                                </div>
                                                <div className="rigth">
                                                    <button type="button" class="btn btn-primary btn-sm btnUbicacionList" 
                                                        style={{ paddingLeft:'0.6rem'}}
                                                        onClick={() => sendLatLng(row)}
                                                        >UBICACIÓN</button>
                                                </div>
                                            </div>

                                        })}
                                    </div>

                                    <MaterialTable
                                        columns={columnsObituario}
                                        data={rowsObituario}
                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: 'No se encontraror registros'
                                            },
                                            toolbar: {
                                                searchTooltip: 'Buscar'
                                            },
                                            pagination: {
                                                labelRowsSelect: 'Registros',
                                                labelDisplayedRows: '{count} de {from}-{to}',
                                                firstTooltip: 'Primera página',
                                                previousTooltip: 'Página anterior',
                                                nextTooltip: 'Próxima página',
                                                lastTooltip: 'Última página',
                                                labelRowsPerPage: 'Registros por página'
                                            }
                                        }}
                                        options={{
                                            sorting: true,
                                            search: false,
                                            toolbar: false,
                                            paging: false,
                                            actionsColumnIndex: -1,
                                            headerStyle: {
                                                backgroundColor: '#575757',
                                                color: '#FFF'
                                            },
                                            rowStyle: rowData => ({
                                                backgroundColor: rowData.tableData.id % 2 === 0? '#F8F8F8' : '#FFFFFF',
                                                textTransform: 'capitalize'
                                            })
                                        }}
                                        actions={[
                                            {
                                                onClick: (event, rowData) => {
                                                    SetUserData(rowData); 
                                                    let coords= rowData?.latlon?.split(",");
                                                    let lat= parseFloat(coords? coords[0] : null);
                                                    let lng= parseFloat(coords? coords[1] : null);
                                                    let coordenada = { lat: lat, lng: lng};
                                                    setCoord(coordenada);
                                                    toggle(); }
                                            }
                                        ]}
                                        components={{
                                            Action: props => (

                                                <button type="button" class="btn btn-info btnUbicacionList" onClick={(event) => props.action.onClick(event, props.data)}>
                                                    Ubicación
                                                </button>
                                            ),
                                        }}
                                    />
                                </div>
                                : <p>Sin resultados</p>}
                        </div>
                    }
                </div>                     
            </section>
            <Footer />
            <Modal
                isShowing={isShowing}
                hide={toggle}
                userData={userData}
                coord={coord}
                generatePDF={generatePDF}/>
        </div>
        
    );
};

export default Obituario;