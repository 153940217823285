import React from "react"
import { DataProvider } from './context'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import HomePage from "./pages/HomePage/HomePage"
import Park from "./pages/Park/Park"
import Informacion from "./pages/Informacion/Informacion"
import Sepultados from "./pages/Sepultados/Sepultados"
import Obituario from "./pages/Obituario/Obituario"
import Preguntas from "./pages/Preguntas/Preguntas"
import Nosotros from "./pages/Nosotros/Nosotros"
import Noticias from "./pages/Noticias/Noticias"
import Fallecimiento from "./pages/Fallecimiento/Fallecimiento"

import './App.css';

function App() {
  return (
    
    <DataProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/parque" component={Park} />
          <Route exact path="/informacion" component={Informacion} />
          <Route exact path="/sepultados" component={Sepultados} />
          <Route exact path="/obituario" component={Obituario} />
          <Route exact path="/preguntas" component={Preguntas} />
          <Route exact path="/nosotros" component={Nosotros} />
          <Route exact path="/noticias" component={Noticias} />
          <Route exact path="/fallecimiento" component={Fallecimiento} />
        </Switch>
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;
