
import React from 'react';
import ReactDOM from 'react-dom';
import GoogleMaps from 'simple-react-google-maps';
import iconLocation from '../../assets/home/iconLocation.svg'; 
import iconDownload from '../../assets/sepultados/iconDownload.svg'; 

const Modal = ({ isShowing, hide, userData, coord, generatePDF }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay2"/>
    <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal2 modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"onClick={hide} ></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="rows">
                            <table style={{ width:'100%'}}>
                                <tr>
                                    <td style={{ width:'45%'}}>
                                        <div className="nameModal">
                                            {userData.nombres} {userData.apaterno} {userData.nombre}
                                        </div>
                                    </td>
                                    <td style={{ width:'55%'}}>
                                        <div class="input-group d-flex justify-content-end mt-2 mb-3">
                                            <button type="button" class="btn btn-primary btn-sm btnDownload iconDownloadPC" onClick={generatePDF}>DESCARGAR</button>
                                            <button type="button" class="btn btn-primary btn-sm btnDownload iconDownloadMobile" onClick={generatePDF}><img src={iconDownload} alt="" /></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width:'45%'}}>
                                        <div className="dataSepultura">
                                            <div className="timePC">Horario: <span className="timeSepultura">{userData.fechasep ? `${userData.fechasep}` : null} {userData.hora ? `${userData.hora}` : null} </span><br/></div>
                                            {`Sector: ${userData.sec}`} <br/>
                                            {`Sepultura: ${userData.sep}`} <br/>
                                    
                                        </div>
                                    </td>
                                    <td className="btnLlegarPC" style={{ width:'55%'}}>
                                        <div class="input-group d-flex justify-content-end mt-2 mb-3">
                                            <div class="input-group-text btnGroupAddon"><img src={iconLocation} alt="" /></div>
                                            {/* <input type="button" class="form-control" placeholder="VER COMO LLEGAR" aria-label="Input group example" aria-describedby="btnGroupAddon"/> */}
                                            <a href="https://www.google.com/maps/dir//-17.7629335,-63.0299836/@-17.762933,-63.029984,841m/data=!3m1!1e3?hl=es-419" target="_blank">
                                            <button type="button" class="btn btn-primary btn-sm btnLlegar">VER COMO LLEGAR</button>
                                            </a>
                                        </div>
                                    </td>
                                    <td className="btnLlegarMobile" style={{ width:'55%'}}>
                                        <div class="input-group d-flex justify-content-end mt-2 mb-3">
                                            <p className="horaPopup">{userData.hora ? `${userData.hora}` : null}</p>
                                        </div>
                                        <div class="input-group d-flex justify-content-end mt-2 mb-3 btnUbicacion">
                                            <div class="input-group-text btnGroupAddon"><img src={iconLocation} alt="" /></div>
                                            {/* <input type="button" class="form-control" placeholder="VER COMO LLEGAR" aria-label="Input group example" aria-describedby="btnGroupAddon"/> */}
                                            <a href="https://www.google.com/maps/dir//-17.7629335,-63.0299836/@-17.762933,-63.029984,841m/data=!3m1!1e3?hl=es-419" target="_blank">
                                            <button type="button" class="btn btn-primary btn-sm btnLlegar">VER COMO LLEGAR</button>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div id="maps" className="mapModal" style={{ height: "400px", width: "100%" }}>
                        <GoogleMaps
                            style={{ height: "400px", with: "100%" }}
                            zoom={16}
                            center={{ lat: coord.lat, lng: coord.lng}}
                            apiKey={"AIzaSyB4DjqbVpEyoPHEF2FI10ONC6JCAeALkXw"}
                            markers={{ lat: coord.lat, lng: coord.lng}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </React.Fragment>, document.body
) : null;

export default Modal;