import React from 'react';
import { QuestionsGenerales, QuestionsSepultaciones, QuestionsReducciones, QuestionsVentas, QuestionsInstalaciones } from './QuestionsConst';
import QuestionAcord from './QuestionAcord';

const Questions = (selectedButton) => {

    return (
        <div>
            <div class="accordion" id="accordionPanelsStayOpenExample">
                {
                    selectedButton.selected === "QuestionsGenerales" ?
                        QuestionsGenerales.map(question => (
                            <QuestionAcord question={question} />
                        )) : (
                            selectedButton.selected === "QuestionsReducciones" ?
                                QuestionsReducciones.map(question => (
                                    <QuestionAcord question={question} />
                                )) : (
                                    selectedButton.selected === "QuestionsVentas" ?
                                        QuestionsVentas.map(question => (
                                            <QuestionAcord question={question} />
                                        )) : (
                                            selectedButton.selected === "QuestionsInstalaciones" ?
                                                QuestionsInstalaciones.map(question => (
                                                    <QuestionAcord question={question} />
                                                )) : (
                                                    selectedButton.selected === "QuestionsSepultaciones" ?
                                                        QuestionsSepultaciones.map(question => (
                                                            <QuestionAcord question={question} />
                                                        )) : QuestionsGenerales.map(question => (
                                                            <QuestionAcord question={question} />
                                                        ))
                                                )
                                        )
                                )
                        )
                }
            </div>
        </div>
    )
}

export default Questions