import React from "react";
import './Noticias.scss'

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Notices from "../../components/Notices.jsx/Notices";

const Noticias = () => {
    return (
        <div className="NoticiasContainer">
            <section class="header-noticias">
                <Navbar />
                <div class="backNoticies"></div> 
                <div class="container-title">
                    <h3>Noticias</h3>
                </div>
            </section>
            <section class="body-noticias">
                
                <Notices />
            </section>
            <Footer />
        </div>
    );
};

export default Noticias;