import React from 'react'
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core';
//import "./Payment1.scss";
//import "./Payment.scss";
import { Divider } from '@material-ui/core';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ThemeProvider } from "@material-ui/styles";
import ReactDOM from 'react-dom';
import imgX from '../../assets/home/x.svg';


const Theme = {
  palette: {
    primary: {
      contrastText: "#FF0000",  // this is just a color (red for example)
      dark: "#2691C7",
      main: "#FF0000",
      light: "#FF0000",
      skyHead: "#2691C7"
    }
  },
  overrides: {   // in this object you can override css classes
    MuiTableHead: {   // overriding of MuiTableHead
      root: {  //overriding root
        //background: "cornflowerblue"
      }
    },
    MuiTableCell: {
      head: {  //overriding root
        color: "white",
        borderRadius: "7px 7px 0 0",
        fontfamily: 'Montserrat !important',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontsize: '18px !important',
        lineHeight: '22px'
      }
    },
    MuiTableFooter: {   // overriding of MuiTableHead
      root: {  //overriding root
        display: "none !important"
      }
    },
  }
};

const theme = createMuiTheme(Theme);


const LoadingModal = ({ hide, label }) => ReactDOM.createPortal(

  <React.Fragment>

    <div className="modal-overlay2" />
    <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal2 modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={
              hide
            } ></button>
          </div>
          <div class="modal-body">
                <div>
                  <h3>{label}</h3>
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Cargando...</span>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment >, document.getElementById('modal')
);

export default LoadingModal
