import React, { useState } from 'react';
import './Mainmodal.scss'
import ReactDOM from 'react-dom';

const Mainmodal = ({ isShowingMain, hideMain }) => {
  const [showMessageMain, setShowMessageMain] = useState(false);

  return (

    isShowingMain ? ReactDOM.createPortal(
      <React.Fragment>
        <div className="modal-overlay2" />
        <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1} role="dialog">
          <div class="modal-dialog modal-dialog-centered-main modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close btn-close-white" onClick={hideMain} ></button>
                <div class="modal-header-center">
                    <h1>ESTIMADAS FAMILIAS DE MEMORIAL PARK</h1>
                    <p class="body-font">Les informamos que, a partir del 01 de Octubre del presente año, Memorial Park
                    dará cumplimiento a la normativa tributaria vigente <b>"Facturación Electrónica"</b> de acuerdo al decreto 
                    cumpliendo los requisitos legales y reglamentarios establecidos en la Resolución Normativa de Directorio 
                    N°102100000011.</p>
                    <p class="body-font">
                    Por consiguiente, en los meses de agosto y septiembre, todos nuestros clientes estarán siendo contactados 
                    a través de la empresa "Black Bird Servicios Generales" con el fin de gestionar levantamiento de información 
                    de su correo electrónico para hacerles llegar sus respectivas facturas electrónicas, por tal motivo solicitamos 
                    su apoyo ofreciendo la información solicitada.<br></br>
                    Cualquier duda por favor comunicarse con su ejecutivo de ventas.
                    </p>
                </div>

                <div class="buttons">
                  <button type="button" class="btn btn-secondary btn-sm" onClick={hideMain}>ENTENDIDO &nbsp;</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>, document.getElementById('modal')
    ) : null
  )
}
export default Mainmodal;
