import React from 'react'
import "./Payment3.scss";
import { Divider } from '@material-ui/core';
import ReactDOM from 'react-dom';
import imgX from '../../assets/home/x.svg';
import webPay from '../../assets/home/webPayPlus.png';
import LoadingModal from "../Utils/LoadingModal"
import axios from 'axios';

const Payment3 = ({ isShowing3, hide3, datos_contratos, nameUpperCase, columnsClient, getCuotas, array_contratos, Cuotas, montoTotal, datosCuotasSepultura, datosCuotasMantenimiento, dataAcount, loading ,dataClient}) => 

isShowing3 && loading ? 

    <LoadingModal></LoadingModal>
    : (isShowing3 && !loading

? ReactDOM.createPortal(

    <React.Fragment>

        <div className="modal-overlay2" />
        <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className="modal2">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hide3} ></button>
                    </div>
                    <div class="modal-body">
                        {montoTotal !== 0 ?
                            <div class="materialTable">
                                <h5 class="modal-title" id="exampleModalLabel" >Formas de pago online</h5>
                                <div className="hrtitleDiv">
                                    <Divider id="hrTitle" />
                                </div>
                                <h6 class="txtContrato">Contrato N° {dataAcount} </h6>
                                <h3 className="txtTxtResu">Resumen</h3>
                                <h6 className="txtTitleCuota">Cuotas de sepultura</h6>
                                <ul class="list-group txtDetalleCuota">
                                    {
                                        datosCuotasSepultura?.map(item =>

                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                {item.cuota}
                                                <span>{item.monto}</span>
                                            </li>
                                        )
                                    }

                                </ul>
                                <h6 className="txtTitleCuota">Cuotas de mantención</h6>
                                <ul class="list-group txtDetalleCuota">
                                    {
                                        datosCuotasMantenimiento?.map(item =>

                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                {item.cuota}
                                                <span>{item.monto}</span>
                                            </li>
                                        )
                                    }

                                </ul>
                                <div className="hrtitleDiv">
                                    <Divider id="hrTitleTotal" />
                                </div>
                                <h6 className="txtMontoTotal">
                                    <span className="txtTotalGral">TOTAL</span> <span className="txtTotalGralMonto">${montoTotal}</span>
                                </h6>
                                <h6 className="txtSeleccionePago">Seleccione su forma de pago</h6>
                                <ul>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <img src={webPay} alt="" style={{ width: '7rem',position:'relative',margin:'0 auto'}}/>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <p style={{ fontSize:"13px",fontWeight: "100", marginTop: '-4px' }}>Ingrese un correo Email y un teléfono para recibir comprobante</p>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <input
                                        id="email"
                                        type="text" class="form-control"
                                        style={{ fontWeight: "300", fontSize: '14px' }}
                                        placeholder="E-mail"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="email"
                                    />
                                     </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">

                                    <input
                                        id="telefono"
                                        type="text" class="form-control"
                                        style={{ fontWeight: "300", fontSize: '14px' }}
                                        placeholder="Teléfono"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        name="telefono"
                                    />
                                     </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                   
                                        <button type="button" class="btn btnMediosDePagos" 
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            style={{ marginRight: '1.1rem'}}
                                            onClick={() => { 
                                                var tipo = 'A';
                                                var nombre = encodeURIComponent(dataClient.CLIENTES.CLIENTE[0].NAME)
                                                var apaterno = encodeURIComponent(dataClient.CLIENTES.CLIENTE[0].A_PATERNO)
                                                var amaterno = encodeURIComponent(dataClient.CLIENTES.CLIENTE[0].A_MATERNO)

                                                //var fono = dataClient.CLIENTES.CLIENTE[0].FONO_C
                                                
                                                var ciudad = encodeURIComponent(dataClient.CLIENTES.CLIENTE[0].CIUDAD)
                                                var comuna = encodeURIComponent(dataClient.CLIENTES.CLIENTE[0].COMUNA)
                                                var direccion = encodeURIComponent(dataClient.CLIENTES.CLIENTE[0].DIRECCION)

                                                var cuotas = ''
                                                var mantencion = ''
                                                var cupones = ''
                                                for(var i = 0;i < datosCuotasSepultura.length;i++){
                                                cuotas += datosCuotasSepultura[i].boleta.replace('Boleta: ','')+'-'
                                                tipo = 'A'
                                                }
                                                for(var i = 0;i < datosCuotasMantenimiento.length;i++){
                                                mantencion += datosCuotasMantenimiento[i].boleta.replace('Boleta: ','')+'-'
                                                tipo = 'B'
                                                }
                                                cupones = cuotas + mantencion
                                                var fono = document.querySelector("#telefono").value;
                                                console.log(fono)
                                                var email = document.querySelector("#email").value
                                                if(email == ''){
                                                    alert('complete el campo email')
                                                }else{
                                                    if(fono == '' && fono.length > 8){
                                                        alert('complete el campo teléfono')
                                                    }else{    
                                                        let d = new Date();
                                                        let dtext = d.toString();
                                                        console.log(`https://apimpark.nuestrosparques.cl/bolivia_webpay?monto=` + `${montoTotal}`+`&ctto=` + `${dataAcount}`+`&cupones=`+ `${cupones.slice(0, -1)}`+`&nombre=`+ `${nombre}`+`&apaterno=`+ `${apaterno}`+`&amaterno=`+ `${amaterno}`+`&fono=`+ `${fono}`+`&comuna=`+ `${comuna}`+`&ciudad=`+ `${ciudad}`+`&direccion=`+ `${direccion}`+`&email=`+ `${email}`+`&tipo=`+ `${tipo}`+`&time=`+ `${dtext}`)
                                                        axios.get(`https://apimpark.nuestrosparques.cl/bolivia_webpay?monto=` + `${montoTotal}`+`&ctto=` + `${dataAcount}`+`&cupones=`+ `${cupones.slice(0, -1)}`+`&nombre=`+ `${nombre}`+`&apaterno=`+ `${apaterno}`+`&amaterno=`+ `${amaterno}`+`&fono=`+ `${fono}`+`&comuna=`+ `${comuna}`+`&ciudad=`+ `${ciudad}`+`&direccion=`+ `${direccion}`+`&email=`+ `${email}`+`&tipo=`+ `${tipo}`+`&time=`+ `${dtext}`)
                                                        .then(res => {
                                                        window.open(res.data.data.redirectUrl, '_blank');
                                                        })
                                                        .catch(e => {
                                                            alert('Hubo un error de comunicación')
                                                            console.log('error')
                                                        });
                                                    }    
                                                }
                                            }
                                            }>
                                            <span>PAGAR</span>
                                        </button>
                                    </li>
                                </ul>

                                



                            </div>
                            :
                            <div class="materialTable">
                                <h5 class="modal-title modalEmpty" id="exampleModalLabel" >No existen datos para el Nro Ingresado</h5>
                                <div className="hrtitleDiv">
                                    <Divider id="hrTitle" />
                                </div>
                                <div className="modalEmpty">
                                    <img src={imgX} alt="" onClick={hide3} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment >, document.getElementById('modal')
) : null);

export default Payment3
