import React from 'react'
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core';
import "./Payment1.scss";
//import "./Payment.scss";
import { Divider } from '@material-ui/core';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ThemeProvider } from "@material-ui/styles";
import ReactDOM from 'react-dom';
import imgX from '../../assets/home/x.svg';
import LoadingModal from "../Utils/LoadingModal"

const Theme = {
  palette: {
    primary: {
      contrastText: "#FF0000",  // this is just a color (red for example)
      dark: "#2691C7",
      main: "#FF0000",
      light: "#FF0000",
      skyHead: "#2691C7"
    }
  },
  overrides: {   // in this object you can override css classes
    MuiTableHead: {   // overriding of MuiTableHead
      root: {  //overriding root
        //background: "cornflowerblue"
      }
    },
    MuiTableCell: {
      head: {  //overriding root
        color: "white",
        borderRadius: "7px 7px 0 0",
        fontfamily: 'Montserrat !important',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontsize: '18px !important',
        lineHeight: '22px'
      }
    },
    MuiTableFooter: {   // overriding of MuiTableHead
      root: {  //overriding root
        display: "none !important"
      }
    },
  }
};

const theme = createMuiTheme(Theme);


const Payment1 = ({ isShowing1, hide, datos_contratos, nameUpperCase, columnsClient, getCuotas, array_contratos, Cuotas, resetInput, isLoading1, loading }) => isShowing1 && loading ? 

<LoadingModal label="Buscando..."></LoadingModal>
: (isShowing1 && !loading ?
ReactDOM.createPortal(

  <React.Fragment>

    <div className="modal-overlay2" />
    <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal2 modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={
              hide,
              resetInput
            } ></button>
          </div>
          <div class="modal-body">
            {
                  datos_contratos ?
                   
                    <div class="materialTable">
                      <h5 class="modal-title" id="exampleModalLabel" >{nameUpperCase}</h5>
                      <div className="hrtitleDiv">
                        <Divider id="hrTitle" />
                      </div>
                      <h6 class="seleccione">Seleccione el contrato al que quiere acceder</h6>
                      <ThemeProvider theme={theme}>
                        <MaterialTable
                          columns={columnsClient}
                          data={datos_contratos}
                          components={{
                            Header: props => {
                              return (
                                <TableHead>
                                  <TableRow style={{ background: "#2691c7" }}>
                                    <TableCell colSpan={2} align="left">
                                      <span className="txtTitleTable">Seleccione un contrato</span>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              );
                            },
                            Row: ({ props, index }) => {
                              return (
                                <TableRow style={index % 2 ? { background: "#E9F4F9" } : { background: "white" }}>
                                  <TableCell>
                                    <span className="txtCtaNro">CONTRATO</span> <span className="nroCtaNro">{array_contratos.length > 0 && array_contratos[index]['NUMERO']}</span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <button type="button" class="btn btnVerCuenta" data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={(e) =>
                                        Cuotas(array_contratos[index])
                                      }>
                                      <span className="btnVerCuentacolor">VER CUOTAS</span>
                                    </button>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          }}
                          options={{
                            sorting: false,
                            search: false,
                            toolbar: false,
                            pageSize: 2,
                            pageSizeOptions: [2, 5, 10],
                            actionsColumnIndex: -1,
                          }}

                        />
                      </ThemeProvider>
                    </div>
                    :
                    <div>
                      <div class="rowEmpty">
                        <h5 class="elementEmpty emptyTitle">El CI o NiT ingresado no cuenta con contrato en Memorial Park.</h5>
                      </div>
                      <div class="rowEmpty">
                        <a className="elementEmpty" onClick={hide}>
                          <button type="button" class="btn btn-primary btn-sm btnOtroCI">
                            intentar con otro CI o NIT
                          </button>
                        </a>
                      </div>
                      <div class="rowEmpty">
                        <div className="elementEmpty txtComEmpty">
                          Si tiene problemas para ingresar, puede comunicarse al (+591) 69052868 – (+591) 77661703.
                        </div>
                      </div>
                    </div>

            }

            {/* {datos_contratos ?
              <div class="materialTable">
                <h5 class="modal-title" id="exampleModalLabel" >{nameUpperCase}</h5>
                <div className="hrtitleDiv">
                  <Divider id="hrTitle" />
                </div>
                <h6 class="seleccione">Seleccione el contrato al que quiere acceder</h6>
                <ThemeProvider theme={theme}>
                  <MaterialTable
                    columns={columnsClient}
                    data={datos_contratos}
                    components={{
                      Header: props => {
                        return (
                          <TableHead>
                            <TableRow style={{ background: "#2691c7" }}>
                              <TableCell colSpan={2} align="left">
                                <span className="txtTitleTable">Seleccione un contrato</span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        );
                      },
                      Row: ({ props, index }) => {
                        return (
                          <TableRow style={index % 2 ? { background: "#E9F4F9" } : { background: "white" }}>
                            <TableCell>
                              <span className="txtCtaNro">CONTRATO</span> <span className="nroCtaNro">{array_contratos.length > 0 && array_contratos[index]['NUMERO']}</span>
                            </TableCell>
                            <TableCell align="right">
                              <button type="button" class="btn btnVerCuenta" data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={(e) =>
                                  Cuotas(array_contratos[index])
                                }>
                                <span className="btnVerCuentacolor">VER CUOTAS</span>
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    }}
                    options={{
                      sorting: false,
                      search: false,
                      toolbar: false,
                      pageSize: 2,
                      pageSizeOptions: [2, 5, 10],
                      actionsColumnIndex: -1,
                    }}

                  />
                </ThemeProvider>
              </div>
              :
              <div>
                <div class="rowEmpty">
                  <h5 class="elementEmpty emptyTitle">El CI o NiT ingresado no cuenta con contrato en Memorial Park.</h5>
                </div>
                <div class="rowEmpty">
                  <a className="elementEmpty" href={hide}>
                    <button type="button" class="btn btn-primary btn-sm btnOtroCI">
                      intentar otro CI o NIT
                    </button>
                  </a>
                </div>
                <div class="rowEmpty">
                  <div className="elementEmpty txtComEmpty">
                    Si tiene problemas para ingresar, puede comunicarse al 69052868 o al 77661703.
                  </div>
                </div>
              </div>

            } */}



          </div>
        </div>
      </div>
    </div>
  </React.Fragment >, document.getElementById('modal')
) : null);

export default Payment1
