import React, { useContext } from 'react';
import { DataContext } from '../../context';
import { types } from '../../Actions';
import "./Notice.scss";
import { Divider } from '@material-ui/core';


function Notice(news) {
    const { store, dispatch } = useContext(DataContext)
    const { newsGeneral } = store;

    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }


    return (

        <div class="card m-3 border-0" key={news.id} style={{ backgroundColor: 'transparent' }}>
            {
                newsGeneral ?
                    <div class={news.news.id === 4 ? "row g-0 mb-4" : "row g-0"}>

                        <div class="col-md-4 imag">
                            <div class="container">
                                <img src={news.news?.image} class="img-fluid rounded-start" alt="..." width="100%" />
                                <div class="content">
                                    <h6 class="titulo">{news.news.titulo} </h6>
                                    <p class="paragraph">{news.news?.cuerpo?.substr(0, 40) + '...'} </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 col">
                            <div class="card-body body">
                                <p class="card-text"><small class="text-muted">{convertDate(news.news.fecha)}</small></p>
                                <h5 class="card-title titulo">{news.news.titulo} </h5>
                                <p class="card-text paragraph">{news.news.cuerpo?.substr(0, 200) + '...'} </p>
                                <a class="btn btn-secondary btn-sm text-white viewMore" href="#" role="button" onClick={() => dispatch(
                                    {
                                        type: types.newsChange,
                                        payload: [{ id: news.news.id, image: news.news.image, fecha: news.news.fecha, titulo: news.news.titulo, cuerpo: news.news.cuerpo }]
                                    }
                                )}>VER MÁS</a>

                            </div>
                        </div>
                        <div className="separationLine">
                            <Divider id="horizontal" />
                        </div>
                    </div>

                    :
                    <div class="row g-0">
                        <div class="col-md-4 imag">
                            <div class="container">
                                <img src={news.news?.image} class="img-fluid rounded-start" alt="..." width="100%" />
                                <div class="content">
                                    <h6 class="titulo">{news.news.titulo} </h6>
                                    <p class="paragraph">{news.news?.cuerpo?.substr(0, 40) + '...'} </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 col">
                            <div class="card-body body">
                                <p class="card-text"><small class="text-muted">{convertDate(news.news.fecha)}</small></p>
                                <h5 class="card-title titulo">{news.news.titulo} </h5>
                                <p class="card-text paragraph">{news.news.cuerpo} </p>
                            </div>
                        </div>
                    </div>
            }

        </div>


    )
}

export default Notice;