import { useState } from 'react';

const useLoading1 = () => {
  const [isLoading1, setisLoading1] = useState(false);
  

  function toggleLoading1() {
    setisLoading1(!isLoading1);
  }

  return {
    isLoading1,
    toggleLoading1,
  }
};

export default useLoading1;