import React from "react";
 
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Pago1 from '../../assets/informacion/pago1.svg';
import Pago2 from '../../assets/informacion/pago2.svg';
import Pago3 from '../../assets/informacion/pago3.svg';
import Pago4 from '../../assets/informacion/pago4.svg';
import Pago5 from '../../assets/informacion/pago5.svg';
import Pago6 from '../../assets/informacion/pago6.svg';
import Pago7 from '../../assets/informacion/pago7.svg';
import Pago8 from '../../assets/informacion/pago8.svg';
import Pago9 from '../../assets/informacion/pago9.svg';
import PagoQr from '../../assets/informacion/pagoQr.svg';
// New Logos
import payBCP from '../../assets/informacion/payBCP.png';
import payLaMerced from '../../assets/informacion/payLaMerced.png';
import payBancoFassil from '../../assets/informacion/payBancoFassil.png';
import payWow from '../../assets/informacion/payWow.png';
import payProgreso from '../../assets/informacion/payProgreso.png';
import payFidalga from '../../assets/informacion/payFidalga.png';
import payNazareno from '../../assets/informacion/payNazareno.png';
import payHiperMaxi from '../../assets/informacion/payHipermaxi.png';
import payBnb from '../../assets/informacion/payBnb.png';
import payCasaChavez from '../../assets/informacion/payCasaChavez.png';
import payFarmacorp from  '../../assets/informacion/payFarmacorp.png';
import payWesternUnion from  '../../assets/informacion/payWesternUnion.png';

import payTigoMoney from  '../../assets/informacion/payTigoMoney.png';
import payMultiCenter from  '../../assets/informacion/payMultiCenter.png';

import rectangle from '../../assets/informacion/Rectangle938.svg';
import { Divider } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import './Informacion.scss'
const Informacion = () => {

    
    
  
    return (
        <div className="InformacionContainer">
            <section class="header-informacion">
                <Navbar />
                <div class="container-title">
                    <h3>Información General</h3>
                </div>
            </section>
            <section class="pago" id="medios-de-pago">
                <p>Seleccione la información que necesita.</p>
                <div className="buttons">
                    <a class="nav-link" href="#scroll-medios-pago"><button type="button" class="btn btn-primary btn-sm" id="medio">Medios de pago</button></a>
                    <a class="nav-link" href="#scroll-tramite"><button type="button" class="btn btn-primary btn-sm" id="tramite">Documentos para trámites</button></a>
                    {/* <a class="nav-link" href="#scroll-legal"><button type="button" class="btn btn-primary btn-sm" id="legal">Documentos legales</button></a> */}
                    <a class="nav-link" href="#scroll-info"><button type="button" class="btn btn-primary btn-sm" id="info">Información de contacto</button></a>
                </div>
                <div id="scroll-medios-pago" className="medios-pago">
                    <div className="title">
                        <h3 >Medios de pago</h3>
                        <p>Como parte del servicio integral de excelencia que entregamos, y para comodidad de nuestros clientes, en Nuestros Parques ponemos a su disposición las siguientes agencias de pago.</p>
                    </div>
                    <div className="payContainer">
                        <div className="payBox">
                            <div className="borderPayVertical-A"></div>
                            <div className="borderPayVertical-B"></div>
                            <div className="payOnline">
                                <div className="box-images">
                                    <h4>Pagos en linea</h4>
                                    <div class="pagos-en-linea-A">
                                        <a href="https://www.memorialpark.com.bo" target="_blank">www.memorialpark.com.bo</a><br></br>
                                    </div>
                                    <div class="pagos-en-linea-B">
                                        <a href="https://www.multipago.bo/service/memorialpark/first" target="_blank">www.multipago.bo/service/memorialpark/first</a>
                                    </div>
                                </div>
                            </div>

                            <div className="hrHorizontalMobile"></div>
                            <div className="payOnline">
                                <div className="box-images">
                                    <h4>Puntos de pago - Presencial</h4>
                                    <p>Con número de Cédula de Identidad titular</p>

                                    <a href="https://www.fassil.com.bo" target="_blank"><img src={payBancoFassil} alt=""/></a>
                                    <a href="https://www.bnb.com.bo/" target="_blank"><img src={payBnb} alt=""/></a>
                                    <a href="https://www.bcp.com.bo" target="_blank"><img src={payBCP} alt=""/></a>
                                    <a href="https://www.farmaciachavez.com.bo/" target="_blank"><img src={payCasaChavez} alt=""/></a>

                                    <a href="https://www.lamerced.coop" target="_blank"><img src={payLaMerced} alt=""/></a>
                                    <a href="http://www.jesus-nazareno.coop/" target="_blank"><img src={payNazareno} alt=""/></a>

                                    <a href="https://es-la.facebook.com/Wowretail/" target="_blank"><img src={payWow} alt=""/></a>
                                    <a href="https://www.coopprogreso.bo/index.php/component/progreso/mapa" target="_blank"><img src={payProgreso} alt=""/></a>
                                    <a href="https://farmacorp.com/" target="_blank"><img src={payFarmacorp} alt=""/></a>
                                    <a href="https://www.westernunion.com/bo/es/find-locations.html" target="_blank"><img src={payWesternUnion} alt=""/></a>
                                                                        
                                    {/* <a href="https://fidalga.com/" target="_blank"><img src={payFidalga} alt=""/></a>
                                    <a href="https://hipermaxi.com/" target="_blank"><img src={payHipermaxi} alt=""/></a> */}
                                </div>
                            </div>

                            <div className="hrHorizontalMobile"></div>
                            {/* <div className="payOnsite"> */}
                            <div className="payOnline">
                                <div className="box-images">
                                    <h4>Solo con código Multipagos</h4>
                                    <p>
                                        También puede realizar su pago con<br/>
                                        <a href="https://www.tigo.com.bo/" target="_blank"><img src={payTigoMoney} alt=""/></a>
                                        <a href="https://fidalga.com/" target="_blank"><img src={payFidalga} alt=""/></a>
                                        <a href="https://hipermaxi.com/" target="_blank"><img src={payHiperMaxi} alt=""/></a>
                                        <a href="https://www.multicenter.com.bo/" target="_blank"><img src={payMultiCenter} alt=""/></a>
                                    </p>
                                </div>
                            </div>
                            <div className="hrHorizontalMobileBottom"></div>
                        </div>
                    </div>
                </div>
            </section>   

            <section id="scroll-tramite" class="tramite">
                <div className="container-title">
                    <img src={rectangle} alt="" />
                    <h3>Documentación para trámites</h3>
                    <p>Aquí encontrará todo sobre los documentos necesarios para realizar diferentes trámites.</p>
                </div>
                <div className="container">
                {/* <div className="owl-carousel owl-theme"> */}
                    <OwlCarousel autoWidth={true} nav={true} className="owl-theme" navClass={['owl-prev-inf','owl-next-inf']} > 
                        <div className="card" >
                            <h3>Reprogramación</h3>
                            <p>Corresponde al proceso de reprogramación de pagos por mora y ajuste de precio, amortización de capital, cambio de valor de la cuota o uso anticipado de precio NF a Precio NI.</p>
                            <a href={process.env.PUBLIC_URL + '/MEMORIAL_PARK_doc_reprogramacion.pdf'} target="_blank">
                                <button type="button" class="btn btn-primary btn-sm">VER MÁS</button>
                            </a>
                        </div>
                        <div className="card" >
                            <h3>Traspaso</h3>
                            <p>Este proceso corresponde al traspaso de una sepultura entre titular y nuevo titular.</p>
                            <a href={process.env.PUBLIC_URL + '/MEMORIAL_PARK_doc_traspaso_por_titular.pdf'} target="_blank">
                                <button type="button" class="btn btn-primary btn-sm">por Titular</button>
                            </a>
                            <a href={process.env.PUBLIC_URL + '/MEMORIAL_PARK_doc_traspaso_por_fallecimiento_del_titular.pdf'} target="_blank">
                                <button type="button" class="btn-sm btnDeath">Por fallecimiento del titular</button>
                            </a>
                        </div>
                        <div className="card" >
                            <h3>Exhumación</h3>
                            <p>Corresponde al proceso de exhumar los restos de un fallecido.</p>
                            <a href={process.env.PUBLIC_URL + '/MEMORIAL_PARK_doc_exhumacion.pdf'} target="_blank">
                                <button type="button" class="btn btn-primary btn-sm">ver más</button>
                            </a>
                        </div>
                        <div className="card" >
                            <h3>Traslado interno</h3>
                            <p>Corresponde al traslado de un fallecido desde una sepultura a otra dentro del mismo Cementerio.</p>
                            <a href={process.env.PUBLIC_URL + '/MEMORIAL_PARK_doc_solicitud_de_traslado_interno.pdf'} target="_blank">
                                <button type="button" class="btn btn-primary btn-sm">ver más</button>
                            </a>
                        </div>
                    </OwlCarousel> 
                {/* </div> */}
                </div>
            </section>    
            {/* <section id="scroll-legal" class="legal">
                        <h3>Documentación Legal</h3>
                        <p>En esta sección podrá conocer toda la información legal</p>
                        <button type="button" class="btn btn-primary btn-sm">DESCARGAR</button>
            </section> */}
            <section id="scroll-info" class="info-contacto">
                    <div className="title">
                        <h3>Información de contacto</h3>
                        <p>En Memorial Park queremos acompañarlo en todo momento. Es por esto que le dejamos toda la información para que se ponga en contacto con nosotros.</p>
                    </div>
                    <div className="subtitles">
                        <div className="title">
                            <strong>Oficinas comerciales</strong>
                            <p>
                                Calle Prolongación Campero #194<br/>
                                Esq Acre (1er  Anillo Zona Maquina Vieja)<br/> 
                                Santa Cruz – Bolivia.<br/> 
                                Teléfonos: (+591) 3361920 - (+591) 3361404<br/>
                            </p>
                        </div>
                        <div className="title">
                            <strong>Memorial Park</strong>
                            <p>                                    
                                Carretera a Cotoca KM12<br/>
                                Visitas: de Lunes a Viernes de 9:00h a 18:00<br/>
                                Teléfonos: (+591) 3882277<br/>
                                Mail: <a href="mailto:info@memorialpark.com.bo">info@memorialpark.com.bo</a>
                            </p>
                        </div>
                        <div className="title">
                            <strong>Emergencias 24h</strong>
                            <strong id="tel">(+591) 77399188</strong>
                            <strong>Consultas pagos</strong>
                            <p>(+591) 69206935 - (+591) 77013619</p>
                        </div>
                    </div>
            </section>
            <Footer />
        </div>
    );
};

export default Informacion;