import React, { useState } from "react";
import './Preguntas.scss';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Questions from '../../components/Questions/Questions';
import Form from "../../components/Form/Form";
import { red } from "@material-ui/core/colors";


const Preguntas = () => {

    const [selectedButton, setSelectedButton] = useState("QuestionsGenerales");
    
    /**
     * Change the color of buttons
     */
    const [toggleStateClassQG, setToggleStateClassQG] = useState(1);
    const [toggleStateClassQS, setToggleStateClassQS] = useState(0);
    const [toggleStateClassQR, setToggleStateClassQR] = useState(0);
    const [toggleStateClassQV, setToggleStateClassQV] = useState(0);
    const [toggleStateClassQI, setToggleStateClassQI] = useState(0);

    const handleClickGenerales = () => {
        setSelectedButton("QuestionsGenerales")
        setToggleStateClassQG(1)
        setToggleStateClassQS(0)
        setToggleStateClassQR(0)
        setToggleStateClassQV(0)
        setToggleStateClassQI(0)
    }
    const handleClickSepul = () => {
        setSelectedButton("QuestionsSepultaciones")
        setToggleStateClassQG(0)
        setToggleStateClassQS(1)
        setToggleStateClassQR(0)
        setToggleStateClassQV(0)
        setToggleStateClassQI(0)
    }

    const handleClickReducc = () => {
        setSelectedButton("QuestionsReducciones")
        setToggleStateClassQG(0)
        setToggleStateClassQS(0)
        setToggleStateClassQR(1)
        setToggleStateClassQV(0)
        setToggleStateClassQI(0)
    }

    const handleClickVentas = () => {
        setSelectedButton("QuestionsVentas")
        setToggleStateClassQG(0)
        setToggleStateClassQS(0)
        setToggleStateClassQR(0)
        setToggleStateClassQV(1)
        setToggleStateClassQI(0)
    }

    const handleClickInsta = () => {
        setSelectedButton("QuestionsInstalaciones")
        setToggleStateClassQG(0)
        setToggleStateClassQS(0)
        setToggleStateClassQR(0)
        setToggleStateClassQV(0)
        setToggleStateClassQI(1)
    }

    // const style= {
    //     height: "41",
    //     lineText: "14"
    // }


    return (
        <div className="PreguntasContainer">
            <section class="header-preguntas">
                <Navbar />
                <div class="container-title">
                    <h3>Preguntas Frecuentes</h3>
                </div>
            </section>
            <section class="body-preguntas">
                <div className="titleFAQ">
                    <p>
                        Seleccione una categoría y haga click en la pregunta para desplegar su respuesta. Si no encuentra su consulta aquí, no dude en contactarnos a través del formulario que se encuentra al final de la página.
                    </p>
                </div>
                <div className="containerQuestion">
                    <div className="container allQuestion">
                        <div id="outerQ">
                            <div class="innerQ"><button className={toggleStateClassQG === 1 ? "btnActive" : "btnDisabled"} onClick={handleClickGenerales}><span className="btnOneLine">ASPECTOS GENERALES</span></button></div>
                            <div class="innerQ"><button className={toggleStateClassQS === 1 ? "btnActive" : "btnDisabled"} onClick={handleClickSepul}>    SEPULTACIONES     </button></div>
                            <span class="narrow1"></span>
                            <div class="innerQ"><button className={toggleStateClassQR === 1 ? "btnActive" : "btnDisabled"} onClick={handleClickReducc}>   REDUCCIONES       </button></div>
                            <div class="innerQ"><button className={toggleStateClassQV === 1 ? "btnActive" : "btnDisabled"} onClick={handleClickVentas}><span className="btnOneLine">VENTAS Y PAGOS</span></button></div>
                            <span class="narrow2"></span>
                            <div class="innerQ"><button className={toggleStateClassQI === 1 ? "btnActive" : "btnDisabled"} onClick={handleClickInsta}>    INSTALACIONES     </button></div>
                        </div>
                        <div className="row text-center" id="textline"></div>
                        <div className="preguntas m-8">
                            <div className="container">
                                <Questions selected={selectedButton} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="body form">
                <Form />
            </section>
            <Footer />
        </div>
    );
};

export default Preguntas;