import React,{ createContext, useState, useEffect, useReducer } from "react";
import axios from 'axios';
import StoreReducer, { InitialStore } from "./Store/StoreReducer";
import useLoading1 from "./pages/HomePage/useLoading1";


export const DataContext = createContext()


export const DataProvider = ({ children }) => {

    const [store, dispatch] = useReducer(StoreReducer, InitialStore)
    const {toggleLoading1} = useLoading1()

    let summaCuota= 0;

    const { dataAcount } = store
    console.log(dataAcount)

    const ENDPOINT = "https://www.memorialpark.com.bo/obituario_admin/";

    const optionsDay = { month: 'long', day: 'numeric', year: 'numeric' };
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const dayAfterTomorrow = new Date(today)
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2)
    const hoy = today.toLocaleDateString("es-ES", optionsDay).split(' de ');
    const mañana = tomorrow.toLocaleDateString("es-ES", optionsDay).split(' de ');
    const pasadoMañana = dayAfterTomorrow.toLocaleDateString("es-ES", optionsDay).split(' de ');


    const [apellidoSepultados, setApellidoSepultados] = useState("");
    const [rowsSepultados, setRowsSepultados] = useState([]);
    const [rowsObituario, setRowsObituario] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataClient, setDataClient] = useState();
    const [numberNit, setNumberNit]= useState();
    const [dataCuotas, setDataCuotas] = useState();


    const columnsSepultados = [
        { field: 'nombres', title: 'Nombre'},
        { field: 'apaterno', title: 'Apellido Paterno'},
        { field: 'amaterno', title: 'Apellido Materno'},
        { field: 'fechasep', title: 'Fecha sepultura', sorting:false},
        { field: 'sec', title: 'Sector'},
        { field: 'sep', title: 'Sepultura'},
        { field: 'latlon', title: 'Ubicacion', hidden: true, sorting:false}
    ];

    const columnsObituario = [
        { field: 'fecha', title: 'Fecha', hidden: true},
        { field: 'nombre', title: 'Nombre'},
        { field: 'hora', title: 'Hora'},
        { field: 'area', title: 'Area'},
        { field: 'sec', title: 'Sector'},
        { field: 'sep', title: 'Sepultura'},
        { field: 'latlon', title: 'Ubicacion', hidden: true, sorting:false}
    ];

    const columnsClient = [
        {field: 'titulo', title: 'Cuotas de sepultura'},
        {field: 'numero', title: ''}
    ]

    const columnsCuotasSepul= [
        {field: 'cuota', title: ''},
        {field: 'vencimiento', title: ''},
        {field: 'boleta', title: ''},
        {field:'monto', title: ''}
    ]
    const columnsCuotasManten=[
        {field: 'cuota', title: ''},
        {field: 'vencimiento', title: ''},
        {field: 'boleta', title: ''},
        {field:'monto', title: ''}

    ]

    const getSepultados = () =>{
        setLoading(true)
        axios.get(`https://www.nuestrosparques.cl/mpservices/bfallecido.php?apaterno=` + `${apellidoSepultados}`)
            .then(res => {
                setRowsSepultados(res.data);
                setLoading(false)
            })
            .catch(e => {
                console.log('error')
                setLoading(false)
            });
    }

    const getObituario = (day)=>{
        setLoading(true)
        axios.get(`https://www.nuestrosparques.cl/mpservices/obituario.php?fecha=` + `${day[0]}` + `%20${day[1].toUpperCase()}` + `%20${day[2]}`)
            .then(res => {
                setRowsObituario(res.data);
                setLoading(false)
            })
            .catch(e => {
                console.log('error')
                setLoading(false)
            });
    }
    const ENDPOINT2= "https://www.nuestrosparques.cl/mpservices/clientes.php?nit="
    const ENDPOINTEXTERNO= "https://lyon.pagatodo360.net:5000/#/deudas/empresa/2222/"
    const getClients= ()=> {
        setLoading(true)
        setDataClient(null)
        axios.get( ENDPOINT2 + `${numberNit}` + `&formato=json`)
        .then(res => {
            setDataClient(res.data)
        })
        .catch(e => {
            console.log('error')           
        })
        .finally(e => {
            setLoading(false)
        })
        // window.open( ENDPOINTEXTERNO + `${numberNit}`, '_blank')
    }

    
    const  getCuotas = (props) =>{
        setLoading(true)
        //console.log("http://intra.nuestrosparques.cl/mpservice/cuotas.php?contrato=" + `${props}` + `&formato=json`)
        axios.get("https://www.nuestrosparques.cl/mpservices/cuotas.php?contrato=" + `${props}` + `&formato=json`)
        .then(res =>{
            console.log(res)
            setDataCuotas(res.data)
        })
        .catch( e => {
            console.log('error')
        })
        .finally(e => {
            setLoading(false)
        })

    }

     
  return(
      <DataContext.Provider value={{
        store, 
        summaCuota,
        dispatch,
        getClients,
        getCuotas,
        dataClient,
        dataCuotas,
        setNumberNit,
        columnsClient,
        columnsCuotasSepul,
        columnsCuotasManten,
        rowsSepultados,
        setRowsSepultados,
        columnsSepultados,
        apellidoSepultados, 
        setApellidoSepultados,
        getSepultados,
        loading,
        hoy,
        mañana,
        pasadoMañana,
        getObituario,
        columnsObituario,
        rowsObituario, 
        setRowsObituario

        
      }}>
          {children}
      </DataContext.Provider>
  )
}

// [store, dispatch]