import React from "react";
import './Fallecimiento.scss' 
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
// import iconSend from '../../assets/fallecimiento/iconTel.svg';
import iconSend from '../../assets/fallecimiento/iconPhone.svg';
import imgDemise1 from '../../assets/home/Fallecimiento1.svg'; 
import imgDemise2 from '../../assets/home/Fallecimiento2.svg'; 
import imgDemise3 from '../../assets/home/Fallecimiento3.svg'; 
import imgDemise4 from '../../assets/home/Fallecimiento4.svg'; 
import iconPaso1 from '../../assets/fallecimiento/iconPaso1.svg'; 
import iconPaso2 from '../../assets/fallecimiento/iconPaso2.svg'; 
import iconPaso3 from '../../assets/fallecimiento/iconPaso3.svg'; 
import iconPaso4 from '../../assets/fallecimiento/iconPaso4.svg'; 
import { Divider } from '@material-ui/core';

import iconDefuncion01 from '../../assets/fallecimiento/iconDefuncion01.svg'; 
import iconDefuncion02 from '../../assets/fallecimiento/iconDefuncion02.svg'; 
import iconSepultacionCI from '../../assets/fallecimiento/iconSepultacionCI.svg'; 
import iconSepultacionStar from '../../assets/fallecimiento/iconSepultacionStar.svg'; 
import iconSepultacionSep from '../../assets/fallecimiento/iconSepultacionSep.svg'; 



const Fallecimiento = () => {

    
    
  
    return (
        <div className="FallecimientoContainer">
            <section class="header-fallecimiento">
                <Navbar />
                <div class="container-title">
                    <h3>En Caso de Fallecimiento</h3>
                </div>
            </section>
            <section class="body-fallecimiento">
                <div className="call24hs">
                    <h3><span>Estamos aquí para apoyarlo y asesorarlo</span></h3>
                    <div class="my-card card">
                        <div class="card-body ">
                            <strong class="d-flex justify-content-center px-2 mb-3 mt-2 mr-6 ml-6">Llámenos las 24hrs</strong>
                            <div className="content-telefono" >
                                <img src={iconSend} alt="" width='21px'/>
                                <strong style={{ marginLeft:'0.5rem'}}>(+591) 77399188</strong>
                                <a href="tel:+59177399188"><button type="button" class="btn btn-secondary btn-sm">LLámenos</button></a>
                            </div>
                        </div>
                    </div>
                    <h3>¿Qué debe hacer en este momento?</h3>
                    <p>Queremos que sepa que estamos aquí para acompañarlo y asesorarlo en este difícil momento.</p>
                </div>
                <section class="body-paso">
                    <div class="paso">
                        <div class="title">
                            <h3>Paso a paso</h3>
                            <p>A continuación, le detallamos los procesos y documentos que necesitará para la sepultación de su ser querido.</p>
                        </div>
                        <div class="card-group">
                            <div class="card">
                                <img src={imgDemise1} class="d-block " width="120px" height="121px" alt=""/>
                                <div class="card-body ">
                                    <h3>Certificado de Defunción</h3>
                                    <p>En primer lugar deberá obtener:</p>
                                    <div className="content">
                                        <img src={iconDefuncion01} alt="" width="45px"/>
                                        <ul>
                                            <li>Certificado de óbito firmado y sellado por el médico: original o fotocopia legalizada (Ventanilla No. 12 de la Prefectura SEDES)</li>
                                            <li> Certificado de defunción en original con viñeta de seguridad otorgado por el oficial de registro civil</li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <img src={iconDefuncion02} alt="" width="45px"/>
                                        <ul>
                                            <li>Fotocopia de carnet de identidad de la persona fallecida</li>
                                            <li>Fotocopia del carnet de identidad vigente del titular del contrato. Si el titular es el fallecido, carnet del familiar directo.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div class="card">
                                <img src={imgDemise2} class="d-block " width="120px" height="121px" alt=""/>
                                <div class="card-body ">
                                    <h3>Adquirir servicio funerario y definir lugar de velación.</h3>
                                    <p> 
                                        Deberá adquirir el servicio funerario y definir el lugar de velación, en caso de no contar con alguno, nosotros lo podemos asesorar.<br/>
                                        Luego, informar a Memorial Park, para coordinar el traslado hacia nuestro parque, con mínimo 6 horas de anticipación.
                                    </p>
                                </div>
                            </div>
                            <Divider  />
                            <div class="card">
                                <img src={imgDemise3} class="d-block " width="120px" height="121px" alt=""/>
                                <div class="card-body ">
                                    <h3>Adquirir sepultura</h3>
                                    <p>
                                        Si no cuenta con Sepultura: para adquirir su sepultura, en Memorial Park contamos con ejecutivos las 24 hrs, que podrán guiarlo y dar solución a sus requerimientos.<br/><br/>
                                        <span>
                                            Teléfono: (+591) 3361920<br/>
                                            Emergencia (+591) 77399188
                                        </span>
                                        <div className="content-telefono contactLeftAlign">
                                            {/* <img src={iconSend} alt="" width='21px'/>
                                            <strong>Emergencia (+591) 77399188</strong> */}
                                            <a href="tel:+59177399188"><button type="button" class="btn btn-secondary btn-sm">LLámenos</button></a>
                                        </div>
                                        <br/>
                                        Si cuenta con Sepultura en Memorial Park: deberá realizar el Trámite de Sepultación en 
                                        Memorial Park.</p>
                                </div>
                            </div>
                            <Divider  />
                            <div class="card">
                                <img src={imgDemise4} class="d-block " width="120px" height="121px" alt=""/>
                                <div class="card-body ">
                                    <h3>Trámite de Sepultación</h3>
                                    <p>Comunicarse al Teléfono de Emergencia</p>
                                    <div className="content-telefono" >
                                        <img src={iconSend} alt="" width='21px'/>
                                        <strong>(+591) 77399188</strong>
                                        <a href="tel:+59177399188"><button type="button" class="btn btn-secondary btn-sm">LLámenos</button></a>
                                    </div>
                                    <p>o apersonarse a las oficinas de Memorial Park, para coordinar la fecha y la hora de la ceremonia de Sepultación.</p>
                                    <h3 style={{ marginTop: '2rem' }}>Cuando el Titular es el fallecido</h3>
                                    <p>Titular o Familiar Responsable presente en oficina, para llenar el formulario de solicitud del Servicio de Sepultación.</p>
                                    <div className="content">
                                        <img src={iconSepultacionCI} alt="" width="38px"/>
                                        <ul>
                                            <li>Fotocopia de C.I. vigente del Titular o del Familiar responsable.</li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <img src={iconSepultacionStar} alt="" width="57px"/>
                                        <ul>
                                            <li>Estar al día en todas sus obligaciones con Memorial Park (cuotas mensuales y de mantenimiento).</li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <img src={iconSepultacionSep} alt="" width="57px"/>
                                        <ul>
                                            <li>
                                                Cancelar el Servicio de Sepultación:
                                                <ul>
                                                    <span className="cancelSepu">
                                                        <li>250$us si es Necesidad Futura (NF)</li>
                                                        <li>350$us si es Necesidad Inmediata (NI)</li>
                                                    </span>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <p style={{ marginBottom: '2rem' }}>En caso de medidas especiales, el Familiar responsable debe proporcionar las medidas del ataúd.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <h3><span className="footerContact">¿Necesita contactarse con nosotros?</span></h3>
                <p className="footerAsesora">Asesoramiento las 24 hrs, al teléfono </p>
                <div className="footerTelefono" >
                    <img src={iconSend} alt="" width='21px'/>
                    <strong>(+591) 77399188</strong>
                    <a href="tel:+59177399188"><button type="button" class="btn btn-secondary btn-sm">LLámenos</button></a>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Fallecimiento;