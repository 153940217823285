import React, { useContext, useState } from "react";
import './HomePage.scss'
import fondo1 from '../../assets/home/fondo-2.jpg';
import fondoM from '../../assets/home/homeMobile.jpg';
import imgPark from '../../assets/home/Group220.svg';
import imgPark2 from '../../assets/home/Group72.png';
import Logo from '../../assets/home/iconMemorialP.svg';
import imgService from '../../assets/home/Group221.svg';
import imgService2 from '../../assets/home/Group73.png';
import imgAbout from '../../assets/home/Group222.png';
// import imgAbout2 from '../../assets/home/Group74.png';
import imgCardUsPC from '../../assets/home/imgHome_CardUs_PC.png';
import imgCardUsMobile from '../../assets/home/imgHome_CardUs_Mobile.png';
import imgDemise1 from '../../assets/home/Fallecimiento1.svg';
import imgDemise2 from '../../assets/home/Fallecimiento2.svg';
import imgDemise3 from '../../assets/home/Fallecimiento3.svg';
import imgDemise4 from '../../assets/home/Fallecimiento4.svg';
import imgNews1 from '../../assets/home/Noticias1.svg';
import imgNews2 from '../../assets/home/Noticias2.svg';
import imgNews3 from '../../assets/home/Noticias3.svg';
import imgNews4 from '../../assets/home/Noticias4.svg';
import rectangle from '../../assets/home/Rectangle269.svg';
import iconSend from '../../assets/home/iconSend.svg';
import iconNext from '../../assets/home/iconNext.svg';
import Navbar from '../../components/Navbar/Navbar';
import { Divider } from '@material-ui/core';
import Graveyard from "../../components/Graveyard/Graveyard";
import Footer from '../../components/Footer/Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { types } from "../../Actions";
import { DataContext } from "../../context";
import Payment1 from "../../components/Payment/Payment1";
import Payment2 from "../../components/Payment/Payment2";
import Payment3 from "../../components/Payment/Payment3";
import useModal1 from "./useModal1";
import useModal2 from "./useModal2";
import useModal3 from "./useModal3";
import useModal from "../../components/Maps/useModal";
import UseModalCoti from "../../components/Cotizacion/UseModalCoti";
import Cotizacion from "../../components/Cotizacion/Cotizacion";
import LoadingModal from "../../components/Utils/LoadingModal"

import useModalMain from "../../components/Mainmodal/useModalMain";
import Mainmodal from "../../components/Mainmodal/Mainmodal";

const HomePage = () => {
  const [homePage, sethomePage] = useState(true)
  const { isShowing1, toggle1 } = useModal1();
  const { isShowing2, toggle2 } = useModal2();
  const { isShowing3, toggle3 } = useModal3();
  const { isShowingCoti, toggleCoti } = UseModalCoti()
  const { setNumberNit, getClients, dataCuotas, getCuotas, columnsClient, dataClient, store, columnsCuotasSepul, dispatch, loading } = useContext(DataContext)
  const { isShowing, toggle } = useModal();
  const { isShowingMain, toggleMain } = useModalMain();
  const [valueCI, setValueCI] = useState("");
  const [errValueCI, setErrValueCI] = useState(false)

  {/*----------------------logica payment1--------------------------*/ }
  const array_contratos = dataClient?.CLIENTES?.CLIENTE[0].CONTRATOS.CONTRATO
  const datos_contratos = array_contratos?.map(item => {
    return { titulo: "Cuenta N°", numero: (item.BASE + item.SERIE + item.NUMERO) }
  })
  const data_name = (dataClient?.CLIENTES?.CLIENTE[0]?.NOMBRE)?.toLowerCase();
  const nameUpperCase = data_name?.replace(/\b\w/g, l => l.toUpperCase())

  console.log(dataClient)
  console.log(dataCuotas)

  {/*----------------------logica payment2--------------------------*/ }

  const { dataAcount, sumaCuota } = store
  const [selected, setselected] = useState(false)
  let arrayCuotas = [];
  for (let i = 0; i < dataCuotas?.cod?.length; i++) {
    if (dataCuotas?.cod[i]["TIPO_MOVIMIENTO"] === "E" && dataCuotas?.cod[i]["ESTADO_PAGO"] === "V") {
      arrayCuotas.push({
        cuota: `CUOTA ` + `${dataCuotas?.cod[i]["NRO_CUOTA"]}`, vencimiento: `vto: ` + `${dataCuotas?.cod[i]["FECHA_VENCIMIENTO"]}`, boleta: `Boleta: ` + `${dataCuotas?.cod[i]["NRO_CUPON"]}`, monto: `$` + `${dataCuotas?.cod[i]["MONTO"]}`
      })
    }
  }

  let arrayCuotasMantenimiento = [];
  for (let i = 0; i < dataCuotas?.cod?.length; i++) {
    if (dataCuotas?.cod[i]["TIPO_MOVIMIENTO"] === "F" && dataCuotas?.cod[i]["ESTADO_PAGO"] === "V") {
      arrayCuotasMantenimiento.push({
        cuota: `CUOTA ` + `${dataCuotas?.cod[i]["NRO_CUOTA"]}`, vencimiento: `vto: ` + `${dataCuotas?.cod[i]["FECHA_VENCIMIENTO"]}`, boleta: `Boleta: ` + `${dataCuotas?.cod[i]["NRO_CUPON"]}`, monto: `$` + `${dataCuotas?.cod[i]["MONTO"]}`
      })
    }
  }
  console.log(arrayCuotasMantenimiento)

  {/*--------------------fin logica payment2----------------------------*/ }

  const resetInput = () => {
    sethomePage(!homePage)
    toggle1()
  }

  const handleInputChange = (e) => {
    e.preventDefault();
    setNumberNit(e.target.value)
    setValueCI(e.target.value)

    valueCI != '' ? setErrValueCI(false) : setErrValueCI(true)
  }

  const clientes = () => {
      // validate the field is empty
      if (valueCI.length > 4){
        setErrValueCI(false)
        getClients()
        toggle1()
      }
      else {
        setErrValueCI(true)
      }
  }


  const Cuotas = (props) => {
    console.log(props)
    getCuotas(`${props.BASE}${props.SERIE}${props.NUMERO}`)
    dispatch(
      {
        type: types.newAcount,
        payload: `${props.BASE}${props.SERIE}${props.NUMERO}`
      }
    )
    toggle1()
    toggle2()
  }
  const [montoTotal, setmontoTotal] = useState(null)
  const setTotalPayment = (props) => {
    console.log(props)
    setmontoTotal(props)
    toggle2()
    toggle3()
  }
  const [datosCuotasSepultura, setdatosCuotasSepultura] = useState(null)
  const datosSeleccionados = (props) => {
    console.log(props)
    setdatosCuotasSepultura(props)
  }
  const [datosCuotasMantenimiento, setdatosCuotasMantenimiento] = useState(null)
  const datosMantenimientoSelecionados = (props) => {
    console.log(props)
    setdatosCuotasMantenimiento(props)
  }

  return (
    <div className="HomePageContainer">
      <div className="header">
        <div className="shadowData"></div>
        <Navbar />
        <div id="carouselHeader" class="my-carousel carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselHeader" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <img src={fondo1} class="fondo" alt="..." />
              <div class="my-caption carousel-caption" style={{ position: 'relative' }}>
                <div className="container">
                  <h3>PREVENIR ES UN ACTO DE AMOR POR LA VIDA</h3>
                  <button type="button" class="btn btn-primary btn-sm" onClick={toggleCoti} style={{ marginTop: '20px' }}>COTIZAR</button>
                  {/* <button type="button" class="btn btn-primary btn-sm" onClick={toggleMain} style={{ marginTop: '20px' }}>COTIZAR2</button> */}
                </div>
              </div>
            </div>
          </div>

          <Cotizacion
            isShowingCoti={isShowingCoti}
            hideCoti={toggleCoti} />

          <Mainmodal isShowingMain={!isShowingMain} hideMain={toggleMain} />

        </div>
        <section class="payment-card ">
          <div class="container d-flex justify-content-center">
            <div class="my-card card">
              <div class="card-body ">
                <div className="content">
                  <strong class="d-flex justify-content-center">Pago en línea</strong>
                  <p style={{ fontWeight: "100", marginTop: '-4px' }}>Ingrese el C.I. o NIT del titular del contrato</p>
                  <input
                    id="input1"
                    type="number" class="form-control"
                    style={{ fontWeight: "300", fontSize: '14px' }}
                    placeholder="C.I. o NiT"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="nit"
                    onChange={handleInputChange} />
                  <p style={{ color: 'red', paddingTop: '1rem' }}
                    className={`${errValueCI ? "showErr" : "hideErr"}`}>
                    C.I.o NIT del titular {valueCI.length == 0 ? "no puede estar en blanco" : "debe tener un mínimo de 5 digitos"}
                  </p>
                  <a
                    href="./informacion#medios-de-pago"
                    style={{ fontWeight: "100", fontSize: '14px' }}
                    class="d-flex justify-content-center py-3">Más lugares y medios de pago</a>
                </div>
                <div className="content" id="button-pay">
                  <button type="button" class="btn btn-secondary btn-sm"
                    onClick={() => clientes()}
                  >CONTINUAR &nbsp;
                    <img src={iconSend} alt="" />
                  </button>

                  <Payment1
                    isShowing1={isShowing1}
                    hide={toggle1}
                    datos_contratos={datos_contratos}
                    nameUpperCase={nameUpperCase}
                    columnsClient={columnsClient}
                    getCuotas={getCuotas}
                    array_contratos={array_contratos}
                    Cuotas={Cuotas}
                    resetInput={resetInput}
                    loading={loading} />

                  <Payment2
                    isShowing2={isShowing2}
                    hide2={toggle2}
                    nameUpperCase={nameUpperCase}
                    arrayCuotas={arrayCuotas}
                    arrayCuotasMantenimiento={arrayCuotasMantenimiento}
                    dataAcount={dataAcount}
                    columnsCuotasSepul={columnsCuotasSepul}
                    selected={selected}
                    sumaCuota={sumaCuota}
                    setTotalPayment={setTotalPayment}
                    datosSeleccionados={datosSeleccionados}
                    datosMantenimientoSelecionados={datosMantenimientoSelecionados}
                    loading={loading} />
                  <Payment3
                    isShowing3={isShowing3}
                    hide3={toggle3}
                    dataClient={dataClient}
                    montoTotal={montoTotal}
                    datosCuotasSepultura={datosCuotasSepultura}
                    datosCuotasMantenimiento={datosCuotasMantenimiento}
                    dataAcount={dataAcount}
                    loading={loading} />

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Cotizacion />
      <section class="park ">
        <h3 class="mb-3">Nuestro Parque</h3>
        {/* Caraousel Parque para mobile */}
        <div id="carouselPark" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
          <div class="park-indicators carousel-indicators">
            <button type="button" data-bs-target="#carouselPark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselPark" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselPark" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={imgPark} class="d-block " alt="..." />
              <div class="carousel-caption d-flex justify-content-center ">
                <img src={Logo} class="d-block " alt="..." width="210px" height="56px" />
                <p>Un espacio único donde honrar y homenajear a sus seres queridos</p>
                {/* <p>Un punto de encuentro para reunirse en familia. Un espacio único donde honrar y homenajear a nuestros seres queridos en espacios rodeados de plena naturaleza, que entregan toda la paz y tranquilidad que solo Memorial Park puede brindar.</p> */}
                <a href="/parque" type="button" class="btn btn-primary btn-sm">CONÓCELO</a>
              </div>
            </div>
            <div class="carousel-item">
              <img src={imgService} class="d-block " alt="..." />
              <div class="carousel-caption d-flex justify-content-center">
                <h3>Servicios</h3>
                <p>Brindamos una atención personalizada durante los momentos difíciles de la vida </p>
                <a href="/parque#servicesHome" type="button" class="btn boxBtn">
                  <button type="button" class="btn btn-primary btn-sm">SERVICIOS</button>
                </a>
              </div>
            </div>
            <div class="carousel-item">
              <img src={imgCardUsMobile} class="d-block " alt="..." />
              <div class="carousel-caption d-flex justify-content-center">
                <h3>Nosotros</h3>
                <p>Llevamos más de 25 años entregando el mejor servicio a nuestra comunidad.</p>
                <a href="/nosotros">
                  <button type="button" class="btn btn-primary btn-sm">SOBRE NOSOTROS</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Parque para PC */}
        <div class="card-group">
          <div class="card text-white">
            <img src={imgPark2} class="card-img" alt="..." height="154px" />
            <div class="card-img-overlay">
              <div className="boxCard">
                <div className="btnPart">
                  <div className="content" style={{ width: '12rem' }}>
                    <img src={Logo} class="d-block " alt="..." width="128px" height="33px" />
                    <a href="/parque" type="button" class="btn boxBtn01">
                      <span>CONOCE <br />NUESTRO PARQUE</span>
                    </a>
                  </div>
                </div>
                <div className="txtPart">
                  <div className="content-p">
                    <p>Un espacio único donde honrar y homenajear a nuestros seres queridos. Un punto de encuentro para reunirse en familia, en espacios rodeados de plena naturaleza, que entregan toda la paz y tranquilidad que solo Memorial Park puede brindar.</p>
                  </div>
                </div>
                <div className="imgPart">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="card text-white" style={{ marginTop: '16px' }}>
            <img src={imgService2} class="card-img" alt="..." height="154px" />
            <div class="card-img-overlay">
              <div className="boxCard">
                <div className="btnPart">
                  <div className="content" style={{ width: '12rem' }}>
                    <h3 className="boxTitle">Servicios</h3>
                    <a href="/parque#servicesHome" type="button" class="btn boxBtn">SERVICIOS</a>
                  </div>
                </div>
                <div className="txtPart">
                  <div className="content-p">
                    <p>Brindamos una atención personalizada durante los momentos difíciles de la vida, poniéndonos a su disposición y brindándole beneficios especiales.</p>
                  </div>
                </div>
                <div className="imgPart">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="card text-white" style={{ marginTop: '16px' }}>
            <img src={imgCardUsPC} class="card-img" alt="..." height="154px" />
            <div class="card-img-overlay">
              <div className="boxCard">
                <div className="btnPart">
                  <div className="content" style={{ width: '12rem' }}>
                    <h3 className="boxTitle">Nosotros</h3>
                    <a href="/nosotros" class="btn boxBtn">SOBRE NOSOTROS</a>
                  </div>
                </div>
                <div className="txtPart">
                  <div className="content-p">
                    <p>Llevamos más de 25 años trabajando para entregar el mejor servicio a nuestra comunidad con la responsabilidad que nos caracteriza. Queremos brindarle toda nuestra experiencia para afrontar estos momentos difíciles a usted y su familia.</p>
                  </div>
                </div>
                <div className="imgPart">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="demise mt-0 demiseFallecimiento">
        <h3>En Caso de Fallecimiento</h3>
        <p class="subTituloFallecimiento">Lo acompañamos en este difícil momento, y le entregamos las herramientas para saber qué hacer.</p>
        {/* Caraousel Fallecimiento para mobile */}
        <div id="carouselDemise" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
          <div class="demise-indicators carousel-indicators">
            <button type="button" data-bs-target="#carouselDemise" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselDemise" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselDemise" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselDemise" data-bs-slide-to="3" aria-label="Slide 4"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active ">
              <img src={imgDemise1} class="d-block " alt="..." width="120px" />
              <div class="carousel-caption d-flex justify-content-center ">
                <h3>Certificado de defunción</h3>
                <p>Obtenga el certificado médico de defunción.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src={imgDemise2} class="d-block " alt="..." />
              <div class="carousel-caption d-flex justify-content-center ">
                <h3>Definir lugar de velación y servicio funerario</h3>
                <p>Contáctese con el servicio funerario e informe el lugar de velación.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src={imgDemise3} class="d-block " alt="..." />
              <div class="carousel-caption d-flex justify-content-center ">
                <h3>Adquirir sepultura y autorizarla</h3>
                <p>Si no cuenta con una sepultura, debe adquirirar. Luego realice el trámite de autorización.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src={imgDemise4} class="d-block " alt="..." />
              <div class="carousel-caption d-flex justify-content-center ">
                <h3>Realizar trámites de sepultación</h3>
                <p>Coordine la ceremonia, la fecha y hora de la misma, y cancele los aranceles correspondientes en nuestros oficinas centrales, en atención al cliente.</p>
              </div>
            </div>
          </div>
        </div>
        {/* Fallecimiento para PC */}
        <div class="card-group">
          <div class="card text-white">
            <span class="imgFallecimiento"><img src={imgDemise1} class="d-block " alt="..." /></span>
            <div class="card-body">
              <h3 class="subTitleItemsFallecimiento">Certificado <br /> de defunción</h3>
              <p>Obtenga el certificado médico de defunción.</p>
            </div>
          </div>
          <div class="card text-white">
            <span class="imgFallecimiento"><img src={imgDemise2} class="d-block " alt="..." /></span>
            <div class="card-body">
              <h3 class="subTitleItemsFallecimiento">Definir lugar de velación y servicio funerario</h3>
              <p>Contáctese con el servicio funerario e informe el lugar de velación.</p>
            </div>
          </div>
          <div class="card text-white">
            <span class="imgFallecimiento"><img src={imgDemise3} class="d-block " alt="..." /></span>
            <div class="card-body">
              <h3 class="subTitleItemsFallecimiento">Adquirir sepultura <br />y autorizarla</h3>
              <p>Si no cuenta con una sepultura, debe adquirirar. Luego realice el trámite de autorización.</p>
            </div>
          </div>
          <div class="card text-white">
            <span class="imgFallecimiento"><img src={imgDemise4} class="d-block " alt="..." /></span>
            <div class="card-body">
              <h3 class="subTitleItemsFallecimiento">Realizar trámites <br />de sepultación</h3>
              <p>Coordine la ceremonia, la fecha y hora de la misma, y cancele los aranceles correspondientes en nuestros oficinas centrales, en atención al cliente.</p>
            </div>
          </div>
        </div>
        <div className="content" style={{ height: '13rem' }}>
          <a href="/fallecimiento"><button type="button" class="btn btn-primary btn-sm btn-MasInfo">MÁS INFORMACIÓN</button></a>
          <p class="mt-3" style={{ fontWeight: '100', width: '20em' }}>Ante cualquier duda comuníquese al <br />(+591) 69052868 – (+591) 77661703</p>
        </div>
      </section>

      <section class="news mt-4">
        <div className="container-title">
          <img src={rectangle} alt="" width="62px" />
          <h3 class="mb-2">Noticias</h3>
          <p>Le contamos todo lo que estamos haciendo en Memorial Park.</p>
          <a href="/noticias"><button type="button" class="btn btn-primary btn-sm">VER TODAS</button></a>
          {/* <Divider orientation="vertical"/> */}
        </div>
        <div className="container">
          <OwlCarousel autoWidth={true} className="owl-theme" nav={true} margin={8} navClass={['owl-prev', 'owl-next']}>
            <div className="card">
              <img src={imgNews1} class="card-img" alt="..." />
              <div className="card-img-overlay">
                <h3>Planta tu amor </h3>
                <p>Por Santa Cruz y Fauna chiquitana</p>
                <button className="btnNews"><a href="/noticias" class="card-link text-warning">VER MÁS</a></button>
              </div>
            </div>
            <div className="card">
              <img src={imgNews2} class="card-img" alt="..." />
              <div className="card-img-overlay">
                <h3 style={{ width: '8em' }}>Salvando vidas</h3>
                <p>Entregamos 3 ambulancias (0)km</p>
                <button className="btnNews"><a href="/noticias" class="card-link text-warning">VER MÁS</a></button>
              </div>
            </div>
            <div className="card">
              <img src={imgNews3} class="card-img" alt="..." />
              <div className="card-img-overlay">
                <h3>Plantar un árbol es plantar vida</h3>
                <p>El 03/10 participamos de la campaña Medio Ambiente.</p>
                <button className="btnNews"><a href="/noticias" class="card-link text-warning">VER MÁS</a></button>
              </div>
            </div>
            <div className="card">
              <img src={imgNews4} class="card-img" alt="..." />
              <div className="card-img-overlay">
                <h3>Reforestación de nuestros bosques</h3>
                <p>Participamos de la tercera precarnavalera.</p>
                <button className="btnNews"><a href="/noticias" class="card-link text-warning">VER MÁS</a></button>
              </div>
            </div>
          </OwlCarousel>
          {/* </div> */}
        </div>
        <div style={{ marginTop: '2rem' }}>
          <a href="/noticias"><button type="button" class="btn btn-primary btn-sm">VER TODOS</button></a>
        </div>
      </section>

      <Graveyard />

      <section class="information">
        <div className="info-general">
          <h3>Información General</h3>
          <p>Teléfonos de Contacto, Horarios del Parque, Nuestras Oficinas Comerciales, y toda la Documentación necesaria para la Realización de Trámites.</p>
          <a href="/informacion"><button type="button" class="btn btn-primary btn-sm mt-2">INGRESAR</button></a>
        </div>
        <div className="payments">
          <h3>Lugares y Medios de Pago</h3>
          <p>Conozca todos los Lugares y Medios de Pago,<br /> disponibles para usted.</p>
          <a href="/informacion#medios-de-pago"><button type="button" class="btn btn-primary btn-sm mt-2">VER TODOS</button></a>
        </div>
      </section>

      <Footer />

    </div>
  );
};

export default HomePage;

const divStyleTextCarousel = {
  position: 'absolute',
  top: '25%',
  left: '4%',
  marginTop: '-25px',
  marginRight: '0',
  marginLeft: '0',
  marginBottom: '-25px'
};

const shadowText = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5
}