import React, { useState } from 'react'
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core';
import "./Payment2.scss";
import { Divider } from '@material-ui/core';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ThemeProvider } from "@material-ui/styles";
import ReactDOM from 'react-dom';
import imgOk from '../../assets/home/ok.svg';
import LoadingModal from "../Utils/LoadingModal"

const ThemeBlue = {
    palette: {
        primary: {
            contrastText: "#FF0000",  // this is just a color (red for example)
            dark: "#2691C7",
            main: "#FF0000",
            light: "#FF0000",
            skyHead: "#2691C7"
        }
    },
    overrides: {   // in this object you can override css classes
        MuiTableHead: {   // overriding of MuiTableHead
            root: {  //overriding root
                background: "cornflowerblue"
            }
        },
        MuiTableCell: {
            head: {  //overriding root
                color: "white",
                borderRadius: "7px 7px 0 0",
                fontfamily: 'Montserrat !important',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontsize: '18px !important',
                lineHeight: '22px'
            }
        },
        MuiTableFooter: {   // overriding of MuiTableHead
            root: {  //overriding root
                display: "none !important"
            }
        },
        MuiTableRow: {   // overriding of MuiTableHead
            root: {  //overriding root
                maxHeight: 20
            }
        },
    }
};

const ThemeOrange = {
    palette: {
        primary: {
            contrastText: "#FF0000",  // this is just a color (red for example)
            dark: "#2691C7",
            main: "#FF0000",
            light: "#FF0000",
            skyHead: "#2691C7"
        }
    },
    overrides: {   // in this object you can override css classes
        MuiTableHead: {   // overriding of MuiTableHead
            root: {  //overriding root
                //background: "chocolate"
            }
        },
        MuiTableCell: {
            head: {  //overriding root
                color: "white",
                borderRadius: "7px 7px 0 0",
                fontfamily: 'Montserrat !important',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontsize: '18px !important',
                lineHeight: '22px'
            }
        },
        MuiTableFooter: {   // overriding of MuiTableHead
            root: {  //overriding root
                display: "none !important"
            }
        },
    }
};

const themeBlue = createMuiTheme(ThemeBlue);
const themeOrange = createMuiTheme(ThemeOrange);

export function Payment2({ isShowing2, hide2, nameUpperCase, arrayCuotasMantenimiento, arrayCuotas, dataAcount, columnsCuotasSepul, setTotalPayment, datosSeleccionados, datosMantenimientoSelecionados, loading } ) {
    console.log(arrayCuotas);
    let totalParcialSepultura = 0;
    let totalParcialMantenimiento = 0;

    let totalLayout = document.getElementById("total_label");

    let arrayCuotaMantenimientoSelected = [];

    let arrayCuotaSepulturaSelected = []
    
    const pageSizeTable = 8;
    const pageSizeTableM = 2;
    
    const disableNextRows = (pos) => {
        for (let index = pos; index < pageSizeTable; index++) {
            let checkElement = document.getElementById(`cuota_sepultura_${index}`)
            checkElement.disabled = true
            checkElement.checked = false
            if (arrayCuotaSepulturaSelected.indexOf(arrayCuotas[index]) >= 0)
                arrayCuotaSepulturaSelected.splice(arrayCuotaSepulturaSelected.indexOf(arrayCuotas[index]),1)
        }
    }

    const disableNextRowsM = (pos) => {
        for (let index = pos; index < pageSizeTableM; index++) {
            let checkElement = document.getElementById(`cuota_mantenimiento_${index}`)
            checkElement.disabled = true
            checkElement.checked = false
            if (arrayCuotaMantenimientoSelected.indexOf(arrayCuotasMantenimiento[index]) >= 0)
                arrayCuotaMantenimientoSelected.splice(arrayCuotaMantenimientoSelected.indexOf(arrayCuotasMantenimiento[index]),1)
        }
    }

    const sumCuotaSepultura = (props) => {
        let nextPos = props.tableData.id + 1;
        let summaCuota = 0;

        if (arrayCuotaSepulturaSelected.indexOf(props) < 0 ) {
            if (nextPos < pageSizeTable){
                let checkElement = document.getElementById(`cuota_sepultura_${nextPos}`)
                if(checkElement !== null){
                    checkElement.disabled = false
                }    
            }
            arrayCuotaSepulturaSelected.push(props)
        }
        else {
            arrayCuotaSepulturaSelected.splice(arrayCuotaSepulturaSelected.indexOf(props),1)
            disableNextRows(nextPos);
        }

        arrayCuotaSepulturaSelected.map(item => {
            summaCuota += (parseFloat(item["monto"].slice(1)))
        })

        totalParcialSepultura = summaCuota;
        RefreshTotal();
    }

    const RefreshTotal = () => {
        totalLayout = document.getElementById("total_label");
        totalLayout.innerHTML = `$${totalParcialSepultura + totalParcialMantenimiento}`
    }

    const sumCuotaMantenimientoLocal = (props) => {
        let nextPos = props.tableData.id + 1;
        let summaMantenimiento = 0;

        if (arrayCuotaMantenimientoSelected.indexOf(props) < 0) {
            if (nextPos < pageSizeTableM){
                let checkElement = document.getElementById(`cuota_mantenimiento_${nextPos}`)
                if(checkElement !== null){
                    checkElement.disabled = false
                }    
            }
            arrayCuotaMantenimientoSelected.push(props)
        }
        else {
            arrayCuotaMantenimientoSelected.splice(arrayCuotaMantenimientoSelected.indexOf(props), 1)
            disableNextRowsM(nextPos);
        }

        arrayCuotaMantenimientoSelected.map(item => {
            summaMantenimiento += (parseFloat(item["monto"].slice(1)))
        })

        totalParcialMantenimiento = summaMantenimiento;
        RefreshTotal();
    }

    return isShowing2 && loading ? 

    <LoadingModal label="Cargando..."></LoadingModal>
    : (isShowing2 && !loading ?
         ReactDOM.createPortal(


        <React.Fragment>
            <div className="modal-overlay2" />
            <div className="modal-wrapper2" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal2 modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hide2}></button>
                        </div>
                        <div class="modal-body">
                            <div class="materialTable" style={{ display: arrayCuotas.length === 0 ? "" : "none" }}>
                                <h5 class="modal-title" id="exampleModalLabel" >{nameUpperCase}</h5>
                                <div className="hrtitleDiv">
                                    <Divider id="hrTitle" />
                                </div>
                                <h6 class="seleccione">CONTRATO N° {dataAcount}</h6>
                                <div className="cardEmptyCenter">
                                    <div class="my-card card cardEmpty">
                                        <div class="card-body ">
                                            <strong class="d-flex justify-content-center px-2 mb-3 mt-2 mr-6 ml-6">
                                                Actualmente no cuenta con cuotas de sepultura
                                                {arrayCuotas.length === 0 && arrayCuotasMantenimiento.length === 0? " y de mantención " : " "}
                                                impagas.
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="materialTable" style={{ display: arrayCuotas.length === 0 ? "none" : "" }}>
                                <h5 class="modal-title" id="exampleModalLabel" >{nameUpperCase}</h5>
                                <div className="hrtitleDiv">
                                    <Divider id="hrTitle" />
                                </div>
                                <h6 class="seleccione">CONTRATO N° {dataAcount}</h6>
                                <h6 class="seleccione">Seleccione las cuotas que quiera pagar</h6>

                                <ThemeProvider theme={themeBlue}>
                                    <MaterialTable style={{ height: arrayCuotas.length === 1 ? "120px" : "auto" }}
                                        columns={columnsCuotasSepul}
                                        data={arrayCuotas}
                                        components={{
                                            Header: props => {
                                                return (
                                                    <TableHead style={{ backgroundcolor: "#2691C7" }}>
                                                        <TableRow>
                                                            <TableCell colSpan={5} align="left">
                                                                <span className="txtTitleTable">Cuotas de Crédito</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                );
                                            },
                                            Row: ({ index }) => {
                                                return (
                                                    <TableRow style={index % 2 ? { background: "#DEEFF7" } : { background: "white" }}>
                                                        <div class="container">
                                                            <div class="row" style={{ margin:'1rem 0'}}>
                                                                <div className="withTable">
                                                                    <span className="nroCtaNro nroCtaNroBold nroCtaNroBoldMobile">{arrayCuotas.length && arrayCuotas[index]["cuota"]}</span><span className="modoPC"><br/></span>
                                                                    <span className="nroCtaNro" style={{marginLeft:"1rem"}} >{arrayCuotas.length && arrayCuotas[index]["vencimiento"]}</span><span className="modoPC"><br/></span>
                                                                    <span className="nroCtaNro" style={{marginLeft:"1rem"}} >{arrayCuotas.length && arrayCuotas[index]["boleta"]}</span><span className="modoPC"><br/></span>
                                                                    {/* <span className="nroCtaNro" style={{marginLeft:"1rem"}} >Interés: $256</span><span className="modoPC"><br/></span> */}
                                                                    <span className="nroCtaNro" style={{marginLeft:"1rem"}} >Monto: {arrayCuotas.length && arrayCuotas[index]["monto"]}</span>
                                                                </div>
                                                                <div style={{ width: '10%', textAlign:'center'}}>
                                                                    <span className="nroCtaNro nroCtaNroBold">{arrayCuotas.length && arrayCuotas[index]["monto"]}</span>
                                                                </div>
                                                                <div style={{ width: '10%'}}>
                                                                    <div>
                                                                        <input type="checkbox" 
                                                                            className="cuotaCheckboxBlue"
                                                                            value="" 
                                                                            id={`cuota_sepultura_${index}`}  { ...( index !== 0 && { disabled: true } ) }
                                                                            onChange={(e) => {
                                                                                sumCuotaSepultura(arrayCuotas[index])
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableRow>
                                                );
                                            }
                                        }}
                                        options={{
                                            sorting: false,
                                            search: false,
                                            toolbar: false,
                                            pageSize: `${pageSizeTable}`,
                                            pageSizeOptions: [`${pageSizeTable}`, `${pageSizeTable}`, 10],
                                            // paginationType: "stepped",
                                            actionsColumnIndex: -1,
                                            // selectionProps: arrayCuotas => ({
                                            //     disabled: arrayCuotas[index] === 0,
                                            //     color: 'primary'
                                            // })
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                            <div className={`materialTable ${arrayCuotasMantenimiento.length === 0 && arrayCuotas.length === 0 ? "bothEmpty" : `${arrayCuotasMantenimiento.length != 0 ? "maintenanceEmpty" : ""}`}`}>
                                <div className="modalEmpty">
                                    <div class="my-card card cardEmpty">
                                        <div class="card-body ">
                                            <strong class="d-flex justify-content-center px-2 mb-3 mt-2 mr-6 ml-6">
                                                Actualmente no cuenta con cuotas de mantención impagas.
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="materialTable" style={{ display: arrayCuotasMantenimiento.length === 0 ? "none" : "" }}>
                                <h6 class="seleccione">Seleccione las cuotas de Mantenimiento que desea pagar</h6>
                                <ThemeProvider theme={themeOrange}>
                                    <MaterialTable 
                                        columns={columnsCuotasSepul}
                                        data={arrayCuotasMantenimiento}
                                        components={{
                                            Header: props => {
                                                return (
                                                    <TableHead style={{ backgroundColor: "#F88F22" }}>
                                                        <TableRow>
                                                            <TableCell colSpan={5} align="left">
                                                                <span className="txtTitleTable">Cuotas de mantención</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                );
                                            },
                                            Row: ({ index }) => {
                                                return (
                                                    <TableRow style={index % 2 ? { background: "#FEEEDE" } : { background: "white" }}>
                                                        <div class="container">
                                                            <div class="row" style={{ margin:'1rem 0'}}>
                                                                <div className="withTable">
                                                                    <span className="nroCtaNro nroCtaNroBold nroCtaNroBoldMobile">{ arrayCuotasMantenimiento.length &&  arrayCuotasMantenimiento[index]["cuota"]}</span><span className="modoPC"><br/></span>
                                                                    <span className="nroCtaNro" style={{marginLeft:"1rem"}} >{arrayCuotasMantenimiento.length && arrayCuotasMantenimiento[index]["vencimiento"]}</span><span className="modoPC"><br/></span>
                                                                    <span className="nroCtaNro" style={{marginLeft:"1rem"}} >{arrayCuotasMantenimiento.length && arrayCuotasMantenimiento[index]["boleta"]}</span><span className="modoPC"><br/></span>
                                                                    {/* <span className="nroCtaNro" style={{marginLeft:"1rem"}} >Interés: $256</span><span className="modoPC"><br/></span> */}
                                                                    <span className="nroCtaNro" style={{marginLeft:"1rem"}} >Monto: {arrayCuotasMantenimiento.length &&  arrayCuotasMantenimiento[index]["monto"]}</span>
                                                                </div>
                                                                <div style={{ width: '10%', textAlign:'center'}}>
                                                                    <span className="nroCtaNro nroCtaNroBold">{arrayCuotasMantenimiento.length &&  arrayCuotasMantenimiento[index]["monto"]}</span>
                                                                </div>
                                                                <div style={{ width: '10%'}}>
                                                                    <div>
                                                                        <input type="checkbox" 
                                                                            className="cuotaCheckboxOrange"
                                                                            value="" 
                                                                            id={`cuota_mantenimiento_${index}`}  { ...( index !== 0 && { disabled: true } ) }
                                                                            onChange={(e) => {
                                                                                sumCuotaMantenimientoLocal(arrayCuotasMantenimiento[index])
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableRow>
                                                );
                                            }
                                        }}
                                        options={{
                                            sorting: false,
                                            search: false,
                                            toolbar: false,
                                            pageSize: 2,
                                            pageSizeOptions: [2, 5, 10],
                                            pageSize: `${pageSizeTableM}`,
                                            pageSizeOptions: [`${pageSizeTableM}`, `${pageSizeTableM}`, 2],
                                            // paginationType: "stepped",
                                            actionsColumnIndex: -1,
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                        <div class="modal-footer" style={{ display:(arrayCuotas.length === 0 && arrayCuotasMantenimiento.length === 0) ? "none" : ""}}>
                            <div className="totalCuotas">
                                <div class="my-card card">
                                    <div class="card-body ">
                                        <div className="MontoPagarContent">
                                            <h5 class="card-title">Total a pagar</h5>
                                            <p id="total_label" class="card-text">$0</p>
                                        </div>
                                        <div className="btnPagarContent">
                                            <button class="btn btn-info btnTotal" data-bs-target="modal" 
                                                data-bs-toggle="modal" data-bs-dismiss="modal"
                                                onClick={() => { 
                                                    setTotalPayment(totalParcialSepultura + totalParcialMantenimiento)
                                                    datosSeleccionados(arrayCuotaSepulturaSelected)
                                                    datosMantenimientoSelecionados(arrayCuotaMantenimientoSelected)
                                                }}>
                                                Continuar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            {/* <a class="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Open first modal</a> */}
        </React.Fragment>, document.getElementById('modal')
    ) : null);
}
export default Payment2
