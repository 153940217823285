import { useState } from 'react';

const useModalMain = () => {
  console.log("useModalMain")
  const [isShowingMain, setIsShowingMain] = useState(false);

  function toggleMain() {
    console.log("toggleMain-isShowingMain", isShowingMain)
    setIsShowingMain(!isShowingMain);
    // console.log("toggleMain2")
  }

  return {
    useModalMain,
    isShowingMain,
    toggleMain,
  }
};

export default useModalMain;