import { useState } from 'react';

const useModal1 = () => {
  const [isShowing1, setIsShowing1] = useState(false);
  const [homePage, sethomePage] = useState(true);

  function toggle1() {
    setIsShowing1(!isShowing1);
    sethomePage(!homePage);
    document.getElementById("input1").value="";
  }

  return {
    homePage,
    isShowing1,
    toggle1,
  }
};

export default useModal1;