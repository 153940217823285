import imgNews1 from '../assets/home/Noticias1.svg';
import imgNews2 from '../assets/home/Noticias4.svg';
import imgNews3 from '../assets/home/Noticias3.svg';
import imgNews4 from '../assets/home/Noticias2.svg';
import { types } from '../Actions';

//initialState es el InitialStore
const InitialStore= {
    news :[
        {id:1, image: imgNews1, fecha: '2019-09-21', titulo: "Planta tu amor por Santa Cruz y Fauna chiquitana", cuerpo: "Memorial Park agradece a DESAFÍO FAUNA CHIQUITANA por haber ayudado a que nuestra actividad “Planta tu amor por Santa Cruz” que se realiza todos los años, en esta oportunidad haya tenido un fin benéfico para la Chiquitania que tanto nos necesita. También agradecemos a las miles de personas que realizaron sus donativos a cambio de plantines, los cuales serán gestionados por Desafío Fauna Chiquitana."},
        {id:2, image: imgNews2, fecha: '2020-02-04', titulo: "Reforestación de nuestros bosques", cuerpo: "Reafirmando nuestro compromiso con la naturaleza, el día sábado participamos de la tercera precarnavalera junto a la comparsa coronadora 2020 “Chiripas jr.”, con quienes repartimos 400 plantines. Agradecemos a los coronadores por la invitación y por incentivar a la reforestación de nuestros bosques."},
        {id:3, image: imgNews3, fecha:'2020-10-05', titulo: "Plantar un árbol es plantar vida", cuerpo: "Reafirmando nuestro compromiso con la Naturaleza, el día sábado 03 de octubre participamos de la campaña de Medio Ambiente “Plantar un árbol es plantar vida”, organizado por la Asociación Internacional de Clubes de Leones. Hicimos la entrega de 60 plantines, los cuales fueron plantados en Satélite Norte, y embellecerán aún más a Santa Cruz. Memorial Park comprometidos con la naturaleza y su familia."},
        {id:4, image: imgNews4, fecha:'2020-10-31', titulo: "Salvando vidas", cuerpo: "Porque nuestro compromiso es con usted, su familia y la naturaleza…Por eso nos sentimos felices y orgullosos de hacer la entrega al Hospital Municipal de la Ciudad de Cotoca, a través de nuestra campaña SALVANDO VIDAS, de 3 ambulancias cero (0) Km debidamente equipadas para la lucha contra el COVID-19."}
    ],

    newsGeneral: true,
    showPayment1: false,
    dataAcount: null,
    sumaCuota: 0,
}

const StoreReducer= (state, action) => {
    switch(action.type){
        case types.newsChange:
            return{
                 news: action.payload,
                 newsGeneral: false
            }

        case types.newNit:
            return {
               // nit: action.payload,
                showPayment1: true,
            }

        case types.newAcount:
            return {
                dataAcount: action.payload,
            }
        case types.suma:
            return {
                sumaCuota: action.payload
            }


            default: 
            return state;
        }

    }

export default StoreReducer
export {InitialStore}