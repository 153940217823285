import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Form = () => {

    const frmContact = { userEmail: '', concernCategory: '', emailDetails: '', nombre: '', Apellido: '', Telefono: '', CI: '', titularCI: '' };
    const [contact, setContact] = useState(frmContact);
    const [showMessage, setShowMessage] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setContact({ ...contact, [name]: value });
    };
    const handleSubmit = e => {
        e.preventDefault();

        emailjs.send('default_service', 'template_k5h82xd', contact, 'user_4lFOCE5PsoRvvRs43ggs8')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setContact(frmContact);
                setShowMessage(true);
            }, (err) => {
                console.log('FAILED...', err);
            });
    }

    return (

        <div className="container pt-2 text-center">
            <form onSubmit={handleSubmit}>
                <div className="pt-3 titleForm">Contacto</div>
                <div className="subTitleForm">
                    Si tiene alguna sugerencia, reclamo o consulta complete los datos y le responderemos a la brevedad.
                </div>
                <div className="pt-3 col-md-5 mx-auto">
                    <div className="form-group optionForm"> 
                        <select required className="form-control" 
                            value={contact.concernCategory} 
                            onChange={handleChange} 
                            name="concernCategory">
                                <option value='' >Seleccciona una opcion</option>
                                <option value="consultas" >Consultas</option>
                                <option value="reclamos">Reclamos</option>
                                <option value="sugerencias">Sugerencias</option>
                                <option value="otros">Otros</option>
                        </select>
                    </div>
                </div>

                <div className="row pt-3 mx-auto">
                    <div className="col-md-3 mx-auto p-2">
                        <div class="form-floating inputForm">
                            <input type="text" class="form-control" id="floatingInput" placeholder="Nombre" onChange={handleChange} name="nombre" value={contact.nombre} />
                            <label for="floatingInput">Nombre</label>
                        </div>
                    </div>
                    <div className="col-md-3 mx-auto p-2">
                        <div class="form-floating inputForm">
                            <input type="text" class="form-control" id="floatingInput" placeholder="Apellido" onChange={handleChange} name="Apellido" value={contact.Apellido} />
                            <label for="floatingInput">Apellido</label>
                        </div>
                    </div>
                    <div className="col-md-3 mx-auto p-2">
                        <div class="form-floating inputForm">
                            <input type="phone" class="form-control" id="floatingInput" placeholder="Telefono" onChange={handleChange} name="Telefono" value={contact.Telefono} />
                            <label for="floatingInput">Telefono</label>
                        </div>
                    </div>
                    <div className="col-md-3 mx-auto p-2">
                        <div class="form-floating inputForm">
                            <input type="number" class="form-control" id="floatingInput" placeholder="number" onChange={handleChange} name="CI" value={contact.CI} />
                            <label for="floatingInput">C.I.</label>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 mx-auto">
                    <div className="col-md-6 mx-auto p-2">
                        <div class="form-floating mb-3 inputForm">
                            <input required type="text" value={contact.userEmail} id="floatingInput" name="userEmail" onChange={handleChange} className="form-control" placeholder="Direccion de Email" />
                            <label for="floatingInput">Direccion de email</label>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
                <div className="pt-3 pb-2 subTitleForm alignLeft">
                    En caso de reclamo o consulta sobre una sepultura en Memorial Park ingrese el C.I. del titular:
                </div>
                <div className="row pt-3 mx-auto">
                    <div className="col-md-6 mx-auto">
                        <div class="form-floating mb-3 inputForm">
                            <input type="text" class="form-control" id="floatingInput" placeholder="CI" onChange={handleChange} name="titularCI" value={contact.titularCI} />
                            <label for="floatingInput">C.I. o NiT del titular de la sepultura</label>
                        </div>
                    </div>
                    <div className="col-md-6 mx-auto"></div>
                </div>
                <div className="pt-3 pb-2 titleMessageForm alignLeft">
                    Deje su mensaje
                </div>
                <div className="row pt-3 mx-auto">
                    <div className="col-md-12 mx-auto">
                        <div class="form-floating mb-3 inputForm">
                            <textarea required name="emailDetails" id="floatingTextarea2" style={{ height: 100 }} onChange={handleChange} className="form-control" placeholder="Escribe tu mensaje aquí" value={contact.emailDetails}></textarea>
                            <label for="floatingTextarea2">Escriba su mensaje aquí</label>
                        </div>
                    </div>
                </div>
                <div className="alignCenterBtn">
                    <button className="btn btnSendForm">Enviar</button>
                </div>
            </form>
        </div >
        // KatherineRosas
        // krosas@memorialpark.com.bo
        // f4tS-z&7JuQLtSp
        // GuillermoRibera
        // gribera@memorialpark.com.bo
    )
}

export default Form


// acces emailJS:

// USER_ID
// user_4lFOCE5PsoRvvRs43ggs8

// ACCES TOKEN
// bf08f59f7e7c60635b16f8e9f0c96407

// TEMPLATE ID
// template_k5h82xd


// ACCESO A emailJS (nombre, email, contraseña)
// KatherineRosas
// krosas@memorialpark.com.bo
// f4tS-z&7JuQLtSp